import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Collapse } from "reactstrap";

interface OrderStatus {
  id: number;
  name: string;
}

interface OrderTracking {
  id: number;
  tracking_number: string;
  carrier: string;
  estimated_delivery_date: string;
  created_at: string; // Date when the status was updated
  orderStatus: OrderStatus;
}

interface OrderTrackingProps {
  orderTracking?: OrderTracking[]; // Made optional
}

const OrderTracking: React.FC<OrderTrackingProps> = ({
  orderTracking = [],
}) => {
  // Default to empty array
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  const toggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  const formatDeliveryDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options).replace(",", " -");
  };

  const renderStatusMessage = (status: string) => {
    switch (status) {
      case "Packed":
        return "Your order has been packed and is ready for shipping.";
      case "Shipped":
        return "Your order has been shipped and is on its way.";
      case "Out For Delivery":
        return "Your order is out for delivery.";
      case "Delivered":
        return "Your order has been delivered.";
      case "Cancelled":
        return "Your order has been cancelled.";
      default:
        return "Order status is being processed.";
    }
  };

  const getIconByStatus = (status: string) => {
    switch (status.trim()) {
      case 'Packed':
        return 'mdi-package-variant'; // Icon for "Packed"
      case 'Shipped':
        return 'mdi-truck'; // Icon for "Shipped"
      case 'Out For Delivery':
        return 'mdi-truck-delivery'; // Icon for "Out For Delivery"
      case 'Delivered':
        return 'mdi-check-circle'; // Icon for "Delivered"
      case 'Cancelled':
        return 'mdi-alert-circle'; // Icon for "Cancelled"
      case 'Returned':
        return 'mdi-undo'; // Icon for "Returned"
      case 'Placed':
        return 'mdi-order-bool-ascending'; // Icon for "Placed"
      default:
        return 'mdi-help-circle'; // Default icon
    }
  };

  const renderStatus = (track: OrderTracking, index: number) => {
    const status = track.orderStatus.name;
    const isActive = openIndex === index;

    return (
      <div
        className="accordion-item border-0"
        key={index}
        onClick={() => toggle(index)}
      >
        <div className="accordion-header">
          <Link
            to="#"
            className={classnames("accordion-button", "p-2", "shadow-none", {
              collapsed: !isActive,
            })}
          >
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 avatar-xs">
                <div className={`avatar-title ${status == "Cancelled" ? "bg-danger": "bg-success"} rounded-circle`}>
                  <i
                    className={`mdi ${getIconByStatus(track.orderStatus.name)}`}
                  ></i>
                </div>
              </div>

              <div className="flex-grow-1 ms-3">
                <h6 className="fs-15 mb-0 fw-semibold">
                  {status} -{" "}
                  <span className="fw-normal">
                    {formatDate(track.created_at)}{" "}
                    {/* Unique date for each track */}
                  </span>
                </h6>
              </div>
            </div>
          </Link>
        </div>
        <Collapse className="accordion-collapse" isOpen={isActive}>
          <div className="accordion-body ms-2 ps-5 pt-0">
            <h6 className="mb-1">{renderStatusMessage(status)}</h6>

            <p className="text-muted mb-0">
              {status === "Delivered" && " Delivered on:"}{" "}
              {formatDeliveryDate(track.created_at)}{" "}
              {/* Unique date for delivered */}
            </p>
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <div className="profile-timeline">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        {orderTracking.map((track, index) => renderStatus(track, index))}
      </div>
    </div>
  );
};

export default OrderTracking;
