import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const PLAN_URL = `${API_URL}/plan`;

const listPlan = () => {
  return axios.get(`${PLAN_URL}/list`).then((response) => {
    return response;
  });
};

const createPlan = (data: any) => {
  return axios.post(`${PLAN_URL}/add`, data).then((response) => {
    return response;
  });
};

const updatePlan = (data: any, id: any) => {
  return axios.put(`${PLAN_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewPlan = (id: any) => {
  return axios.get(`${PLAN_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deletePlan = (id: any) => {
  return axios.put(`${PLAN_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${PLAN_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listPlan,
  createPlan,
  updatePlan,
  viewPlan,
  deletePlan,
  checkDuplicate,
};
