import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/order`;
const CUSTOM_URL = `${process.env.REACT_APP_API_URL}/custom`;

const listOrder = (data?: any) => {
  return axios.post(`${API_URL}/list`, data).then((response) => {
    return response;
  });
};

const listOrderStatus = () => {
  return axios.get(`${CUSTOM_URL}/list-order-status`).then((response) => {
    return response;
  });
};
const listPaymentMethod = () => {
  return axios.get(`${CUSTOM_URL}/list-payment-method`).then((response) => {
    return response;
  });
};

export { listOrder, listOrderStatus, listPaymentMethod };
