import React from "react";
import { Navigate } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";


// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import Variant from "../pages/Variant";
import Category from "../pages/Category";
import Specification from "../pages/Specification";
import Features from "../pages/Features";
import Brand from "../pages/Brand";
import Role from "../pages/Roles";
import Employee from "../pages/Employee";
import User from "../pages/User";
import { ViewUser } from "../pages/User/ViewUser";
import Products from "../pages/Products";
import Orders from "../pages/Orders";
import AddProduct from "../pages/Products/AddProduct";
import OrderDetail from "../pages/Orders/OrderDetail";
import DashboardEcommerce from "../pages/Dashboard";
import Plan from "../pages/Plan";
import PlanFeatures from "../pages/Plan Features";
import Subscription from "../pages/Subscription";
import { ViewSubscription } from "../pages/Subscription/ViewSubscription";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import PlanType from "../pages/Plan Type";
import EcommerceProductDetail from "../pages/Products/ProductDetail";
import EditProduct from "../pages/Products/EditProduct";
import OrderReports from "../pages/Reports/orders";
import ActivityLog from "../pages/Activity Log";



const authProtectedRoutes = [

  //tuymai

  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/products", component: <Products /> },
  { path: "/products/add", component: <AddProduct /> },
  { path: "/products/edit/:id", component: <EditProduct /> },
  { path: "/products/view/:id", component: <EcommerceProductDetail /> },
  { path: "/variant", component: <Variant/> },
  { path: "/category", component: <Category/> },
  { path: "/specification", component: <Specification/> },
  { path: "/features", component: <Features/> },
  { path: "/brand", component: <Brand/> },
  { path: "/role", component: <Role/> },
  { path: "/employee", component: <Employee/> },
  { path: "/employee/view/:id", component: <Employee/> },
  { path: "/user", component: <User/> },
  { path: "/user/view/:id", component: <ViewUser/> },
  { path: "/orders", component: <Orders /> },
  { path: "/orders/view/:id", component: <OrderDetail /> },
  { path: "/order-report", component: <OrderReports /> },
  { path: "/plan", component: <Plan /> },
  { path: "/plan-features", component: <PlanFeatures /> },
  { path: "/plan-type", component: <PlanType /> },
  { path: "/subscription", component: <Subscription /> },
  { path: "/subscription/view/:id", component: <ViewSubscription /> },
  { path: "/activity-log", component: <ActivityLog /> },


  //User Profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/change-password", component: <ChangePassword /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };