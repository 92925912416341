import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import "./ecommerce.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
// Redux
import { useDispatch } from "react-redux";
import { addNewProduct as onAddNewProduct } from "../../slices/thunks";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Import React FilePond
import { registerPlugin } from "react-filepond";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  createProduct,
  listBrand,
  listCategory,
  listFeatuer,
  listSpeacification,
  listVariant,
} from "./_requests";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { createSpecification } from "../../pages/Specification/_requests";
import { createVariant } from "../../pages/Variant/_requests";
import { createFeatures } from "../../pages/Features/_requests";
import { createCategory } from "../../pages/Category/_requests";
import { createBrand } from "../../pages/Brand/_requests";
import defaultProductImage from "../../assets/images/product-default.png";
import { useToast } from "../../Components/Common/ToastContext";
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
type BrandOption = {
  label: any;
  value: any;
};
type CategoryOption = {
  label: any;
  value: any;
};
type SpecificationOption = {
  label: any;
  value: any;
};
type FeatureOption = {
  label: any;
  value: any;
};

const AddProduct = (props: any) => {
  document.title = " Tuymai | Admin panel";
  const { userProfile } = useProfile();
  const { showToast } = useToast();
  const history = useNavigate();

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      short_description: "",
      category: "",
      brand: "",
      width: "",
      weight: "",
      breath: "",
      length: "",
      hsn_code: "",
      status: "",
      cgst: "",
      sgst: "",
      igst: "",
      visibility: "",
      publishedDate: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter a Product Name"),
      weight: Yup.string().required("Please Enter Item Weight"),
      width: Yup.string().required("Please Enter Item Width"),
      length: Yup.string().required("Please Enter Item Length"),
      breath: Yup.string().required("Please Enter Item Breath"),
      hsn_code: Yup.string().required("Please Enter HSN Code"),
      status: Yup.string().required("Please Select status"),
      cgst: Yup.string().required("Please Enter Cgst"),
      sgst: Yup.string().required("Please Enter Sgst"),
      igst: Yup.string().required("Please Enter Igst"),
      brand: Yup.string().required("Please Enter Product Brand"),
      category: Yup.string().required("Please Enter Product Category"),
    }),

    onSubmit: async (values) => {
      const formData = new FormData();

      // Append simple fields (strings, numbers)
      formData.append("name", values.name);
      formData.append("brand", values.brand);
      formData.append("category", values.category);
      formData.append("shortDescription", values.short_description);
      formData.append("productDescription", productDescription);
      formData.append("status", values.status);
      formData.append("publishDate", values.publishedDate);
      formData.append("publishStatus", values.status);
      formData.append("visibility", values.visibility);
      formData.append("igst", values.igst.toString());
      formData.append("sgst", values.sgst.toString());
      formData.append("cgst", values.cgst.toString());
      formData.append("hsn_code", values.hsn_code);
      formData.append("width", values.width.toString());
      formData.append("weight", values.weight.toString());
      formData.append("length", values.length.toString());
      formData.append("breath", values.breath.toString());
      formData.append("createdBy", userProfile.userId);
      formData.append("tags", tags as any); // Ensure this is a string or valid data type

      // Construct formatted data
      const formattedData = formatVariantData(variantData, availableVariants);

      // Append formatted data as a JSON string
      if (formattedData.length > 0) {
        formData.append("variantData", JSON.stringify(formattedData)); // Serialize the entire array as a JSON string
      }

      // Append specification data as a JSON string
      if (Array.isArray(specification) && specification.length > 0) {
        formData.append("specification", JSON.stringify(specification)); // Serialize the array as a JSON string
      }

      // Append feature data as a JSON string
      if (Array.isArray(feature) && feature.length > 0) {
        formData.append("feature", JSON.stringify(feature)); // Serialize the array as a JSON string
      }

      // Handle single file (productImage)
      if (selectedImage) {
        formData.append("productImage", selectedImage);
      }

      // Handle multiple files (productGallery)
      if (selectedFiles && selectedFiles.length > 0) {
        selectedFiles.forEach((file: any) => {
          formData.append("productGallery", file); // Append each file individually
        });
      }

      // Save new product
      const res: any = await createProduct(formData);
      if (res.status === true) {
        showToast(res.message, res.status);
        validation.resetForm();
        history("/products");
      } else {
        showToast(res.message, res.status);
      }
    },
  });

  // Function to format variant data
  const formatVariantData = useCallback(
    (variantData: any, availableVariants: any) => {
      return variantData
        .map((variant: any) => {
          // Initialize a string to hold variant combinations
          let variantString = "";

          // Find matching keys in availableVariants
          const matchedKeys = Object.keys(variant).filter((key) => {
            // Skip price, stock, and skuId in comparison
            if (key === "offer_value" || key === "offer_type" || key === "price" || key === "stock" || key === "skuId") {
              return false; // Skip these fields in comparison
            }

            // Check if the key exists in availableVariants
            const available = availableVariants.find(
              (variant: any) => variant.name === key
            );
            if (available) {
              // If key exists, add to variantString
              variantString += `${available.id}-${variant[key]},`;
              return true; // Key matched
            }
            return false; // Key did not match any available variant
          });

          // If no keys matched, return null
          if (matchedKeys.length === 0) {
            return null; // No matching keys
          }

          // Remove trailing comma from variantString
          variantString = variantString.slice(0, -1); // Remove last comma

          return {
            variant: variantString,
            offer_type: variant.offer_type, 
            offer_value: variant.offer_value, 
            price: variant.price, 
            stock: variant.stock, 
            skuId: variant.skuId, 
          };
        })
        .filter(Boolean); // Filter out null values
    },
    []
  );

  const dateFormat = (selectedDates: Date[]) => {
    // Ensure selectedDates is an array and has at least one date
    if (!Array.isArray(selectedDates) || selectedDates.length === 0) {
      return;
    }
    // Get the first selected date
    const dateString = selectedDates[0];
    // Parse the date string into a Date object
    const parsedDate = new Date(dateString);

    // Format the date to YYYY-MM-DDTHH:MM:SS format (ISO 8601 without timezone)
    const formattedDate = parsedDate.toISOString().split(".")[0]; // Removes the milliseconds and 'Z' (timezone)

    // Create a formatted date string
    // Set the formatted date in validation
    validation.setFieldValue("publishedDate", formattedDate);
  };
  // Example price options
  const priceOptions = [
    { value: "", label: "Select Offer Type" },
    { value: 1, label: "Percentage" },
    { value: 2, label: "Price" },
  ];
  useEffect(() => {
    listBrand().then((res: any) => {
      setAvailableBrand(res.data);
    });
    listSpeacification().then((res: any) => {
      setAvailableSpecification(res.data);
    });
    listVariant().then((res: any) => {
      setAvailableVariants(res.data);
    });
    listFeatuer().then((res: any) => {
      setAvailableFeature(res.data);
    });
    listCategory().then((res: any) => {
      setAvailableCategories(res.data);
    });
  }, []);

  const [customActiveTab, setcustomActiveTab] = useState<any>("1");
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isBrandModal, setIsBrandModal] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [BrandError, setBrandError] = useState("");

  const [availableBrand, setAvailableBrand] = useState<any[]>([]);

  const handleBrand = async () => {
    if (newBrand.trim() === "") {
      setBrandError("Please enter a brand name.");
      return;
    }

    const formData = new FormData();
    formData.append("name", newBrand.trim());
    if (selectedBrandImage) {
      formData.append("icon", selectedBrandImage);
    }
    formData.append("created_by", userProfile?.userId);
    await createBrand(formData).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listBrand().then((res: any) => {
          setAvailableBrand(res.data);
        });
        // Reset modal state
        setNewBrand("");
        setBrandError("");
        setIsBrandModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleBrandModal = () => setIsBrandModal(!isBrandModal);

  const [previewBrandImage, setPreviewBrandImage] = useState<string | null>(
    null
  ); // Image preview
  const [selectedBrandImage, setSelectedBrandImage] = useState<File | null>(
    null
  ); // Selected image

  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue.trim()]);
      }
      setInputValue(""); // Clear input after adding tag
    }
  };

  const handleRemoveTag = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newVariant, setNewVariant] = useState("");
  const [varaiantError, setVaraiantError] = useState("");

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleSave = async () => {
    if (newVariant.trim()) {
      const data = {
        name: newVariant,
        created_by: userProfile.userId,
      };
      await createVariant(data).then((res: any) => {
        if (res.status === true) {
          toast.success(res.message, { autoClose: 3000 });
          listVariant().then((res: any) => {
            setAvailableVariants(res.data);
          });
          setIsModalOpen(false);
        } else {
          toast.error(res.message, { autoClose: 3000 });
        }
      });
    } else {
      setVaraiantError("Please enter a variant name.");
    }
  };

  const [variant, setVariant] = useState([{ variant: "", value: "" }]); // Ensure `variant` is initialized as an empty string
  const [availableVariants, setAvailableVariants] = useState<any[]>([]);
  const [variantData, setVariantData] = useState<any[]>([]); // Store the variant combinations

  // Assuming `availableVariants` and `variant` are defined in your component
  const groupedVariants = useMemo(() => {
    // Group the variants by their type (e.g., Color, Size)
    const grouped = variant.reduce((acc, curr) => {
      const variantType =
        availableVariants.find((variant) => variant.id === curr.variant)
          ?.name || "Unknown"; // Get the name from availableVariants

      if (!acc[variantType]) {
        acc[variantType] = [];
      }
      acc[variantType].push(curr.value);
      return acc;
    }, {} as Record<string, string[]>);

    return grouped;
  }, [variant, availableVariants]);

  // Generate all possible combinations based on grouped variants
  const generateCombinations = () => {
    const variantKeys = Object.keys(groupedVariants);

    // Generate cartesian product of all variant values
    const generateCartesianProduct = (arr: any[][]) =>
      arr.reduce(
        (a, b) => a.flatMap((x: any) => b.map((y: any) => [].concat(x, y))),
        [[]]
      );

    const combinations = generateCartesianProduct(
      variantKeys.map((key) => groupedVariants[key])
    ).map((combo) => {
      const comboObject: any = {};
      variantKeys.forEach((key, index) => {
        comboObject[key] = combo[index];
      });
      return {
        ...comboObject,
        offer_type: "",
        offer_value: "",
        price: "",
        stock: "",
        skuId: "",
      };
    });
    setVariantData(combinations);
  };

  // Handle updating the price and stock for each combination
  const handleCombinationChange = (
    index: number,
    field: string,
    value: any
  ) => {
    const updatedCombinations = [...variantData];
    updatedCombinations[index][field] = value;
    setVariantData(updatedCombinations);
  };

  const [isSpecificationModal, setIsSpecificationModal] = useState(false);
  const [specification, setSpecification] = useState([
    { specification: "", value: "" },
  ]);
  const [newSpecification, setNewSpecification] = useState("");
  const [specificationError, setSpecificationError] = useState("");
  const [selectedParentSpecification, setSelectedParentSpecification] =
    useState<SpecificationOption | null>(null);
  const [availableSpecification, setAvailableSpecification] = useState<any[]>(
    []
  );

  const handleSpecification = async () => {
    if (newSpecification.trim() === "") {
      setSpecificationError("Please enter a Specification name.");
      return;
    }

    // Save logic for Specification and parent Specification (e.g., update the available Specification)
    const data = {
      name: newSpecification,
      specification_id: selectedParentSpecification
        ? selectedParentSpecification.value
        : null,
      created_by: userProfile?.userId,
    };
    await createSpecification(data).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listSpeacification().then((res: any) => {
          setAvailableSpecification(res.data);
        });
        // Reset modal state
        setNewSpecification("");
        setSelectedParentSpecification(null);
        setSpecificationError("");
        setIsSpecificationModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleSpecificationModal = () =>
    setIsSpecificationModal(!isSpecificationModal);

  const handleSpecificationAddRow = () => {
    const lastSpecification = specification[specification.length - 1];
    // Add a new row with the same variant value as the last selected variant
    setSpecification([
      ...specification,
      { specification: lastSpecification.specification, value: "" },
    ]);
  };

  const handleSpecificationRemoveRow = (index: any) => {
    const updatedRows = specification.filter((_, i) => i !== index);
    setSpecification(updatedRows);
  };

  const handleSpecificationChange = (index: any, field: any, newValue: any) => {
    const updatedRows = specification.map((row, i) =>
      i === index ? { ...row, [field]: newValue } : row
    );
    setSpecification(updatedRows);
  };
  const [isFeatureModal, setIsFeatureModal] = useState(false);
  const [feature, setFeature] = useState([{ feature: "", value: "" }]);
  const [newFeature, setNewFeature] = useState("");
  const [featureError, setFeatureError] = useState("");
  const [selectedParentFeature, setSelectedParentFeature] =
    useState<FeatureOption | null>(null);
  const [availableFeature, setAvailableFeature] = useState<any[]>([]);

  const handleFeature = async () => {
    if (newFeature.trim() === "") {
      setFeatureError("Please enter a Feature name.");
      return;
    }

    // Save logic for Feature and parent Feature (e.g., update the available Feature)
    const data = {
      name: newFeature,
      featuer_id: selectedParentFeature ? selectedParentFeature.value : null,
      created_by: userProfile.userId,
    };

    await createFeatures(data).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listFeatuer().then((res: any) => {
          setAvailableFeature(res.data);
        });
        // Reset modal state
        setNewFeature("");
        setSelectedParentFeature(null);
        setFeatureError("");
        setIsFeatureModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleFeatureModal = () => setIsFeatureModal(!isFeatureModal);

  const handleFeatureAddRow = () => {
    const lastFeature = feature[feature.length - 1];
    // Add a new row with the same Feature value as the last selected Feature
    setFeature([...feature, { feature: lastFeature.feature, value: "" }]);
  };

  const handleFeatureRemoveRow = (index: any) => {
    const updatedRows = feature.filter((_, i) => i !== index);
    setFeature(updatedRows);
  };

  const handleFeatureChange = (index: any, field: any, newValue: any) => {
    const updatedRows = feature.map((row, i) =>
      i === index ? { ...row, [field]: newValue } : row
    );
    setFeature(updatedRows);
  };

  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");

  const [selectedParentCategory, setSelectedParentCategory] =
    useState<CategoryOption | null>(null);
  const [availableCategories, setAvailableCategories] = useState<any[]>([]);

  const handleCategory = async () => {
    if (newCategory.trim() === "") {
      setCategoryError("Please enter a category name.");
      return;
    }

    // Save logic for category and parent category (e.g., update the available categories)
    const data = {
      name: newCategory,
      category_id: selectedParentCategory ? selectedParentCategory.value : null,
      created_by: userProfile.userId,
    };

    await createCategory(data).then((res: any) => {
      if (res.status === true) {
        toast.success(res.message, { autoClose: 3000 });
        listCategory().then((res: any) => {
          setAvailableCategories(res.data);
        });
        // Reset modal state
        setNewCategory("");
        setSelectedParentCategory(null);
        setCategoryError("");
        setIsCategoryModal(false);
      } else {
        toast.error(res.message, { autoClose: 3000 });
      }
    });
  };
  const toggleCategoryModal = () => setIsCategoryModal(!isCategoryModal);

  const handleAddRow = () => {
    const lastVariant = variant[variant.length - 1];
    // Add a new row with the same variant value as the last selected variant
    setVariant([...variant, { variant: lastVariant.variant, value: "" }]);
  };

  const handleRemoveRow = (index: any) => {
    const updatedRows = variant.filter((_, i) => i !== index);
    setVariant(updatedRows);
  };

  const handleCategoryChange = (index: any, field: any, newValue: any) => {
    const updatedRows = variant.map((row, i) =>
      i === index ? { ...row, [field]: newValue } : row
    );
    setVariant(updatedRows);
  };

  const handleAcceptedFiles = (files: any) => {
    const updatedFiles = files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );
    setSelectedFiles((prevFiles: any) => [...prevFiles, ...updatedFiles]);
  };

  const removeFile = (index: number) => {
    setSelectedFiles((prevFiles: any[]) => {
      // Create a shallow copy of the array and remove the file at the given index
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1); // Remove the file at the specific index
      return updatedFiles;
    });
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const productStatus = [
    {
      options: [
        { label: "Select Publish status", value: "" },
        { label: "Draft", value: 1 },
        { label: "Published", value: 2 },
        { label: "Scheduled", value: 3 },
      ],
    },
  ];

  const productVisibility = [
    {
      options: [
        { label: "Select product visibility", value: "" },
        { label: "Hidden", value: 1 },
        { label: "Public", value: 2 },
      ],
    },
  ];

  // image
  const [selectedImage, setSelectedImage] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>(null); // Image preview
  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: any
  ) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];

      // Store the file object in state
      field === "formImage" && setSelectedImage(file);
      field === "modalImage" && setSelectedBrandImage(file);

      // Optional: Generate a base64 preview (for image preview purposes)
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          field === "formImage" && setPreviewImage(e.target.result as string); // Store the base64 string for preview
          field === "modalImage" &&
            setPreviewBrandImage(e.target.result as string); // Store the base64 string for preview
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: "38px", // Customize the height to match Bootstrap's Input height
    }),
  };
  console.log("validation.errors", validation.errors);

  return (
    <div className="page-content">
      <ToastContainer closeButton={false} limit={1} />
      <Container fluid>
        <BreadCrumb title="Create Product" pageTitle="Ecommerce" />

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-title-input">
                      Product Name
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      placeholder="Enter product Name"
                      name="name"
                      value={validation.values.name || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.name && validation.touched.name
                          ? true
                          : false
                      }
                    />
                    {validation.errors.name && validation.touched.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div>
                    <Label>Product Description</Label>
                    <CKEditor
                      editor={ClassicEditor as any}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                          "insertTable",
                          "imageUpload",
                          "mediaEmbed",
                          "undo",
                          "redo",
                        ],
                        ckfinder: {
                          uploadUrl: `${process.env.REACT_APP_API_URL}/upload`, // Replace with your actual server endpoint
                        },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setProductDescription(data);
                      }}
                    />
                  </div>
                </CardBody>
              </Card>

              <Card className="pb-4">
                <CardHeader>
                  <h5 className="card-title mb-0">Product Gallery</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-4">
                    <h5 className="fs-14 mb-1">Product Image</h5>
                    <p className="text-muted">Add Product main Image.</p>
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute top-100 start-100 translate-middle">
                          <Label
                            htmlFor="brand-image-input"
                            className="mb-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Select Image"
                          >
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            name="formImage"
                            className="form-control d-none"
                            id="brand-image-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={(e) => handleImageChange(e, "formImage")}
                            invalid={
                              validation.touched.image &&
                              validation.errors.image
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="avatar-lg">
                          <div className="avatar-title bg-light rounded">
                            <img
                              src={previewImage}
                              id="product-img"
                              alt=""
                              className="avatar-md h-auto"
                            />
                          </div>
                        </div>
                      </div>
                      {validation.errors.image && validation.touched.image ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.image}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                  <div></div>
                  <h5 className="fs-14 mb-1">Product Gallery</h5>
                  <p className="text-muted">Add Product Gallery Images.</p>

                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <div className="mb-3 mt-5">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h5>Drop files here or click to upload.</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="list-unstyled mb-0" id="file-previews">
                    <div className="list-unstyled mb-0" id="file-previews">
                      {selectedFiles.map((file: any, index: number) => (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={index + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={file.name}
                                  src={
                                    file?.preview &&
                                    file?.type?.startsWith("video/")
                                      ? defaultProductImage // Use a default video thumbnail for video files
                                      : file.preview || defaultProductImage // Use preview or default product image
                                  }
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {file.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{file.formattedSize}</strong>
                                </p>
                              </Col>
                              <Col className="col-auto">
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => removeFile(index)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Brand</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    {" "}
                    <Link
                      to="#"
                      className="float-end text-decoration-underline"
                      onClick={toggleBrandModal}
                    >
                      Add New
                    </Link>
                    Select product Brand
                  </p>

                  <select
                    className={`form-control ${
                      validation.errors.brand && validation.touched.brand
                        ? "is-invalid"
                        : ""
                    }`}
                    id="price-select"
                    name="brand"
                    aria-placeholder="select offer type"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    value={validation.values.brand}
                  >
                    <option value="">Select Brand</option>
                    {availableBrand.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  {validation.touched.brand && validation.errors.brand ? (
                    <FormFeedback type="invalid">
                      {validation.errors.brand}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Categories</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    {" "}
                    <Link
                      to="#"
                      className="float-end text-decoration-underline"
                      onClick={toggleCategoryModal}
                    >
                      Add New
                    </Link>
                    Select product category
                  </p>

                  <select
                    className={`form-control ${
                      validation.errors.category && validation.touched.category
                        ? "is-invalid"
                        : ""
                    }`}
                    id="price-select"
                    name="category"
                    aria-placeholder="select offer type"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    value={validation.values.category}
                  >
                    <option value="">Select Category</option>
                    {availableCategories.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>

                  {validation.touched.category && validation.errors.category ? (
                    <FormFeedback type="invalid">
                      {validation.errors.category}
                    </FormFeedback>
                  ) : null}
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Tags</h5>
                </CardHeader>
                <CardBody>
                  <div className="tag-input-container">
                    <div className="form-control d-flex flex-wrap align-items-center">
                      {tags.map((tag, index) => (
                        <span
                          key={index}
                          className="badge bg-primary me-2 mb-2"
                        >
                          {tag}
                          <span
                            className="ms-1 cursor-pointer"
                            onClick={() => handleRemoveTag(index)}
                          >
                            &times;
                          </span>
                        </span>
                      ))}
                      <input
                        type="text"
                        name="tags" // Add name attribute for Formik
                        placeholder="Enter tags"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        style={{ minWidth: "100px" }}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Short Description</h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    Add short description for product
                  </p>
                  <textarea
                    className="form-control"
                    placeholder="Must enter minimum of a 100 characters"
                    value={validation.values.short_description}
                    onChange={(e) =>
                      validation.setFieldValue(
                        "short_description",
                        e.target.value
                      )
                    }
                    rows={3}
                  ></textarea>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Publish</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-status-input"
                      className="form-label"
                    >
                      Status
                    </Label>
                    <select
                      className={`form-control ${
                        validation.errors.status && validation.touched.status
                          ? "is-invalid"
                          : ""
                      }`}
                      id="price-select"
                      name="status"
                      aria-placeholder="select offer type"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      value={validation.values.status}
                    >
                      {productStatus.map((item, key) => (
                        <React.Fragment key={key}>
                          {item.options.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                    </select>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-visibility-input"
                      className="form-label"
                    >
                      Visibility
                    </Label>
                    <Input
                      name="visibility"
                      type="select"
                      className="form-select"
                      id="choices-publish-status-input"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.visibility || ""}
                    >
                      {productVisibility.map((item, key) => (
                        <React.Fragment key={key}>
                          {item.options.map((item, key) => (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          ))}
                        </React.Fragment>
                      ))}
                    </Input>
                  </div>
                  {validation.values.status == 3 && (
                    <div>
                      <label
                        htmlFor="datepicker-publish-input"
                        className="form-label"
                      >
                        Publish Date & Time
                      </label>
                      <Flatpickr
                        name="publishedDate"
                        id="publishedDate-field"
                        className="form-control"
                        placeholder="Select a date"
                        options={{
                          enableTime: true,
                          altInput: true,
                          altFormat: "d M, Y, h:i K",
                          dateFormat: "d M, Y, h:i K",
                        }}
                        onChange={(selectedDates) => dateFormat(selectedDates)}
                        value={validation.values.publishedDate || ""}
                      />
                      {validation.touched.publishedDate &&
                      validation.errors.publishedDate ? (
                        <FormFeedback type="invalid">
                          {validation.errors.publishedDate}
                        </FormFeedback>
                      ) : null}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        Tax Info
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        Inventory
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        Specifications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      >
                        Features
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "5",
                        })}
                        onClick={() => {
                          toggleCustom("5");
                        }}
                      >
                        Variant
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "6",
                        })}
                        onClick={() => {
                          toggleCustom("6");
                        }}
                      >
                        Shipment
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>

                <CardBody>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="1">
                      <Row></Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              HSN Code
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="hsn_code"
                              placeholder="Enter HSN code"
                              value={validation.values.hsn_code || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.hsn_code &&
                                validation.touched.hsn_code
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.hsn_code &&
                            validation.touched.hsn_code ? (
                              <FormFeedback type="invalid">
                                {validation.errors.hsn_code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              IGST
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="igst"
                              placeholder="Enter igst percentage"
                              value={validation.values.igst || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.igst &&
                                validation.touched.igst
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.igst &&
                            validation.touched.igst ? (
                              <FormFeedback type="invalid">
                                {validation.errors.igst}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              CGST
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="cgst"
                              placeholder="Enter cgst percentage"
                              value={validation.values.cgst || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.cgst &&
                                validation.touched.cgst
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.cgst &&
                            validation.touched.cgst ? (
                              <FormFeedback type="invalid">
                                {validation.errors.cgst}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="hsn-code-input"
                            >
                              SGST
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="hsn-code-input"
                              name="sgst"
                              placeholder="Enter sgst percentage"
                              value={validation.values.sgst || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.sgst &&
                                validation.touched.sgst
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.sgst &&
                            validation.touched.sgst ? (
                              <FormFeedback type="invalid">
                                {validation.errors.sgst}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  {/* <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="2">
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="manufacturer-name-input"
                            >
                              SKU Id
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="manufacturer-name-input"
                              name="skuId"
                              placeholder="Enter sku id"
                              value={validation.values.skuId || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.skuId &&
                                validation.touched.skuId
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.skuId &&
                            validation.touched.skuId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.skuId}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="manufacturer-brand-input"
                            >
                              Stock
                            </label>
                            <Input
                              type="number"
                              className="form-control"
                              id="manufacturer-brand-input"
                              name="stock"
                              placeholder="Enter stock"
                              value={validation.values.stock || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.stock &&
                                validation.touched.stock
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.stock &&
                            validation.touched.stock ? (
                              <FormFeedback type="invalid">
                                {validation.errors.stock}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent> */}
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="3">
                      <Row>
                        <FormGroup>
                          <p className="mb-2 col-lg-4">
                            {" "}
                            <Link
                              to="#"
                              className="float-end text-decoration-underline"
                              onClick={toggleSpecificationModal}
                            >
                              Add New
                            </Link>
                            Product Specifications
                          </p>
                          <TransitionGroup>
                            {specification.map((row, index) => (
                              <CSSTransition
                                key={index}
                                timeout={300}
                                classNames="variant-row"
                              >
                                <Row className="mb-2 align-items-center">
                                  <Col lg={4}>
                                    <Select
                                      className="mb-2"
                                      value={{
                                        label:
                                          availableSpecification.find(
                                            (spec) =>
                                              spec.id === row.specification // Change from `value` to `id`
                                          )?.name || "", // Use the correct label here
                                        value: row.specification,
                                      }}
                                      onChange={(selectedOption: any) => {
                                        handleSpecificationChange(
                                          index,
                                          "specification",
                                          selectedOption?.value // Use `value` from selected option
                                        );
                                      }}
                                      options={availableSpecification.map(
                                        (spec) => ({
                                          label: spec.name,
                                          value: spec.id,
                                        })
                                      )}
                                      placeholder="Select Specification"
                                      styles={customStyles} // Optional: custom styles to match Bootstrap Input styling
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="mb-2"
                                      placeholder="Enter value"
                                      value={row.value}
                                      onChange={(e) =>
                                        handleSpecificationChange(
                                          index,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      color="primary"
                                      className="me-2"
                                      onClick={handleSpecificationAddRow}
                                      disabled={
                                        index !== specification.length - 1
                                      }
                                    >
                                      +
                                    </Button>

                                    {specification.length > 1 && (
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          handleSpecificationRemoveRow(index)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </FormGroup>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="4">
                      <Row>
                        <FormGroup>
                          <p className="mb-2 col-lg-4">
                            <Link
                              to="#"
                              className="float-end text-decoration-underline"
                              onClick={toggleFeatureModal}
                            >
                              Add New
                            </Link>
                            Product Feature
                          </p>
                          <TransitionGroup>
                            {feature.map((row, index) => (
                              <CSSTransition
                                key={index}
                                timeout={300}
                                classNames="variant-row"
                              >
                                <Row className="mb-2 align-items-center">
                                  <Col lg={4}>
                                    <Select
                                      className="mb-2"
                                      value={{
                                        label:
                                          availableFeature.find(
                                            (feat) => feat.id === row.feature // Change from `feature.value` to `feat.id`
                                          )?.name || "", // Use the correct property for the label
                                        value: row.feature,
                                      }}
                                      onChange={(selectedOption: any) => {
                                        handleFeatureChange(
                                          index,
                                          "feature",
                                          selectedOption?.value // Ensure selected value is passed correctly
                                        );
                                      }}
                                      options={availableFeature.map((feat) => ({
                                        label: feat.name,
                                        value: feat.id,
                                      }))}
                                      placeholder="Select Feature"
                                      styles={customStyles} // Optional: custom styles to match Bootstrap Input styling
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Input
                                      type="text"
                                      className="mb-2"
                                      placeholder="Enter value"
                                      value={row.value}
                                      onChange={(e) =>
                                        handleFeatureChange(
                                          index,
                                          "value",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      color="primary"
                                      className="me-2"
                                      onClick={handleFeatureAddRow}
                                      disabled={index !== feature.length - 1}
                                    >
                                      +
                                    </Button>
                                    {feature.length > 1 && (
                                      <Button
                                        color="danger"
                                        onClick={() =>
                                          handleFeatureRemoveRow(index)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </FormGroup>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="5">
                      <Row>
                        <FormGroup>
                          <p className="col-lg-4">
                            <Link
                              to="#"
                              className="float-end text-decoration-underline"
                              onClick={toggleModal}
                            >
                              Add New
                            </Link>
                            Product Variants
                          </p>
                          <TransitionGroup>
                            {variant.map((row, index) => (
                              <CSSTransition
                                key={index}
                                timeout={300}
                                classNames="variant-row"
                              >
                                <Row className="mb-2 align-items-center">
                                  <Col lg={4}>
                                    <Select
                                      className="mb-2"
                                      value={{
                                        label:
                                          availableVariants.find(
                                            (variant) =>
                                              variant.id === row.variant // Change from `value` to `id`
                                          )?.name || "", // Use the correct label here
                                        value: row.variant,
                                      }}
                                      onChange={(selectedOption: any) => {
                                        handleCategoryChange(
                                          index,
                                          "variant",
                                          selectedOption?.value
                                        );
                                      }}
                                      options={availableVariants.map(
                                        (variant) => ({
                                          label: variant.name,
                                          value: variant.id,
                                        })
                                      )}
                                      placeholder="Select Variant"
                                      styles={customStyles} // Optional: custom styles to match Bootstrap Input styling
                                    />
                                  </Col>
                                  <Col lg={4}>
                                    {typeof row.variant === "string" &&
                                    row.variant.toLowerCase() === "color" ? (
                                      <Input
                                        type="color" // Change input type to color picker
                                        className="mb-2"
                                        value={row.value}
                                        onChange={(e) =>
                                          handleCategoryChange(
                                            index,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <Input
                                        type="text"
                                        placeholder="Enter value"
                                        className="mb-2"
                                        value={row.value}
                                        onChange={(e) =>
                                          handleCategoryChange(
                                            index,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      color="primary"
                                      className="me-2"
                                      onClick={handleAddRow}
                                      disabled={index !== variant.length - 1} // Disable for non-last rows
                                    >
                                      +
                                    </Button>

                                    {variant.length > 1 && (
                                      <Button
                                        color="danger"
                                        onClick={() => handleRemoveRow(index)}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </FormGroup>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <Button
                            color="primary"
                            onClick={generateCombinations}
                          >
                            Generate Combinations
                          </Button>
                        </Col>

                        {/* Dynamic table for combinations */}
                        {variantData.length > 0 && (
                          <div className="table-responsive mt-3">
                            <Table bordered className="table">
                              <thead>
                                <tr>
                                  <th className="text-center w-10">S.No</th>
                                  {Object.keys(groupedVariants).map(
                                    (variantKey) => (
                                      <th
                                        key={variantKey}
                                        className="text-center w-10"
                                      >
                                        {variantKey}
                                      </th>
                                    )
                                  )}
                                  <th className="text-center w-150">
                                    offerType
                                  </th>
                                  <th className="text-center w-150">
                                    offerValue
                                  </th>
                                  <th className="text-center w-150">Price</th>
                                  <th className="text-center w-150">Stock</th>
                                  <th className="text-center w-150">Sku Id</th>
                                </tr>
                              </thead>
                              <tbody>
                                {variantData.map((combo, index) => (
                                  <tr key={index}>
                                    <td className="text-center">{index + 1}</td>
                                    {Object.keys(groupedVariants).map(
                                      (variantKey) => (
                                        <td
                                          key={variantKey}
                                          className="text-center"
                                        >
                                          {combo[variantKey]}
                                        </td>
                                      )
                                    )}
                                    <td>
                                      <select
                                        className="form-control"
                                        name="offer_type"
                                        aria-placeholder="select offer type"
                                        value={combo.offer_type}
                                        onChange={(e) =>
                                          handleCombinationChange(
                                            index,
                                            "offer_type",
                                            e.target.value
                                          )
                                        }
                                      >
                                        {priceOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      <Input
                                        type="text"
                                        value={combo.offer_value}
                                        onChange={(e) =>
                                          handleCombinationChange(
                                            index,
                                            "offer_value",
                                            e.target.value
                                          )
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="number"
                                        value={combo.price}
                                        onChange={(e) =>
                                          handleCombinationChange(
                                            index,
                                            "price",
                                            e.target.value
                                          )
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="number"
                                        value={combo.stock}
                                        onChange={(e) =>
                                          handleCombinationChange(
                                            index,
                                            "stock",
                                            e.target.value
                                          )
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </td>
                                    <td>
                                      <Input
                                        type="text"
                                        value={combo.skuId}
                                        onChange={(e) =>
                                          handleCombinationChange(
                                            index,
                                            "skuId",
                                            e.target.value
                                          )
                                        }
                                        className="form-control form-control-sm"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        )}
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={customActiveTab}>
                    <TabPane id="addproduct-general-info" tabId="6">
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="manufacturer-name-input"
                            >
                              Item Weight
                            </label>
                            <Input
                              type="number"
                              className="form-control"
                              id="manufacturer-name-input"
                              name="weight"
                              placeholder="Enter item weight"
                              value={validation.values.weight || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.weight &&
                                validation.touched.weight
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.weight &&
                            validation.touched.weight ? (
                              <FormFeedback type="invalid">
                                {validation.errors.weight}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="manufacturer-brand-input"
                            >
                              Item Length
                            </label>
                            <Input
                              type="number"
                              className="form-control"
                              id="manufacturer-brand-input"
                              placeholder="Enter  item length"
                              name="length"
                              value={validation.values.length || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.length &&
                                validation.touched.length
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.length &&
                            validation.touched.length ? (
                              <FormFeedback type="invalid">
                                {validation.errors.length}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="manufacturer-name-input"
                            >
                              Item Breath
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="manufacturer-name-input"
                              placeholder="Enter item breath"
                              name="breath"
                              value={validation.values.breath || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.breath &&
                                validation.touched.breath
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.breath &&
                            validation.touched.breath ? (
                              <FormFeedback type="invalid">
                                {validation.errors.breath}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label
                              className="form-label"
                              htmlFor="manufacturer-brand-input"
                            >
                              Item Width
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="manufacturer-brand-input"
                              name="width"
                              placeholder="Enter item width"
                              value={validation.values.width || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.width &&
                                validation.touched.width
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.width &&
                            validation.touched.width ? (
                              <FormFeedback type="invalid">
                                {validation.errors.width}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="text-end mb-3">
            <button type="submit" className="btn btn-success w-sm">
              Submit
            </button>
          </div>
        </Form>
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Add New Variant</ModalHeader>
          <ModalBody>
            <Label for="variantName">Variant Name</Label>
            <Input
              type="text"
              id="variantName"
              placeholder="Enter variant name"
              value={newVariant}
              onChange={(e) => {
                setNewVariant(e.target.value);
                setVaraiantError("");
              }}
            />
            {(varaiantError || newVariant === "") && (
              <span className="text-danger">{varaiantError}</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isCategoryModal} toggle={toggleCategoryModal}>
          <ModalHeader toggle={toggleCategoryModal}>
            Add New Category
          </ModalHeader>
          <ModalBody>
            <div>
              <Label for="categoryName">Category Name</Label>
              <Input
                type="text"
                id="categoryName"
                placeholder="Enter category name"
                value={newCategory}
                onChange={(e) => {
                  setNewCategory(e.target.value);
                  setCategoryError("");
                }}
              />
              {/* Display error message */}
              {(categoryError || newCategory === "") && (
                <span className="text-danger">{categoryError}</span>
              )}
            </div>

            <Label className="mt-3">Parent Category (Optional)</Label>
            <Select
              value={selectedParentCategory}
              onChange={(option: any) =>
                setSelectedParentCategory(option as CategoryOption)
              }
              options={availableCategories.map((spec) => ({
                label: spec.name,
                value: spec.id,
              }))}
              placeholder="Select Parent Category "
              isClearable
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleCategoryModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleCategory}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isSpecificationModal} toggle={toggleSpecificationModal}>
          <ModalHeader toggle={toggleSpecificationModal}>
            Add New Specification
          </ModalHeader>
          <ModalBody>
            <div>
              <Label for="SpecificationName">Specification Name</Label>
              <Input
                type="text"
                id="SpecificationName"
                placeholder="Enter specification name"
                value={newSpecification}
                onChange={(e) => {
                  setNewSpecification(e.target.value);
                  setSpecificationError("");
                }}
              />
              {/* Display error message */}
              {(specificationError || newSpecification === "") && (
                <span className="text-danger">{specificationError}</span>
              )}
            </div>

            <Label className="mt-3">Parent Specification (Optional)</Label>
            <Select
              value={selectedParentSpecification}
              onChange={(option: any) =>
                setSelectedParentSpecification(option as SpecificationOption)
              }
              options={availableSpecification.map((spec) => ({
                label: spec.name,
                value: spec.id,
              }))}
              placeholder="Select Parent Specification "
              isClearable
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleSpecificationModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleSpecification}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isFeatureModal} toggle={toggleFeatureModal}>
          <ModalHeader toggle={toggleFeatureModal}>Add New Feature</ModalHeader>
          <ModalBody>
            <div>
              <Label for="FeatureName">Feature Name</Label>
              <Input
                type="text"
                id="FeatureName"
                placeholder="Enter feature name"
                value={newFeature}
                onChange={(e) => {
                  setNewFeature(e.target.value);
                  setFeatureError("");
                }}
              />
              {/* Display error message */}
              {(featureError || newFeature === "") && (
                <span className="text-danger">{featureError}</span>
              )}
            </div>

            <Label className="mt-3">Parent Feature (Optional)</Label>
            <Select
              value={selectedParentFeature}
              onChange={(option: any) =>
                setSelectedParentFeature(option as FeatureOption)
              }
              options={availableFeature.map((spec) => ({
                label: spec.name,
                value: spec.id,
              }))}
              placeholder="Select Parent Feature "
              isClearable
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleFeatureModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleFeature}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isBrandModal} toggle={toggleBrandModal}>
          <ModalHeader toggle={toggleBrandModal}>Add Brand</ModalHeader>
          <ModalBody>
            <div className="text-center">
              <div className="position-relative d-inline-block">
                <div>
                  Brand Logo <span className="text-danger">*</span>
                </div>
                <div className="position-absolute  bottom-0 end-0">
                  <Label htmlFor="product-image-input" className="mb-0">
                    <div className="avatar-xs cursor-pointer">
                      <div className="avatar-title bg-light border rounded-circle text-muted">
                        <i className="ri-image-fill"></i>
                      </div>
                    </div>
                  </Label>
                  <Input
                    name="brandImage"
                    className="form-control d-none"
                    id="product-image-input"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(e) => handleImageChange(e, "modalImage")}
                  />
                </div>
                <div className="avatar-lg p-1">
                  <div className="avatar-title bg-light rounded-circle">
                    <img
                      src={previewBrandImage || dummyImg}
                      alt="dummyImg"
                      id="customer-img"
                      className="avatar-md rounded-circle object-fit-cover"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Label for="BrandName">Brand Name</Label>
              <Input
                type="text"
                id="BrandName"
                placeholder="Enter brand name"
                value={newBrand}
                onChange={(e) => {
                  setNewBrand(e.target.value);
                  setBrandError(""); // Clear error when typing
                }}
              />
              {/* Display error message */}
              {BrandError && <span className="text-danger">{BrandError}</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleBrandModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleBrand}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default AddProduct;
