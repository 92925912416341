import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Specification_URL = `${API_URL}/specification`;

const listSpecification = () => {
  return axios.get(`${Specification_URL}/list`).then((response) => {
    return response;
  });
};

const createSpecification = (data: any) => {
  return axios.post(`${Specification_URL}/add`, data).then((response) => {
    return response;
  });
};

const updateSpecification = (data: any, id: any) => {
  return axios.put(`${Specification_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewSpecification = (id: any) => {
  return axios.get(`${Specification_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteSpecification = (id: any) => {
  return axios.put(`${Specification_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Specification_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listSpecification,
  createSpecification,
  updateSpecification,
  viewSpecification,
  deleteSpecification,
  checkDuplicate,
};
