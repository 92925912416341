import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Brand_URL = `${API_URL}/brand`;

const listBrand = () => {
  return axios.get(`${Brand_URL}/list`).then((response) => {
    return response;
  });
};

const createBrand = (formData: FormData) => {
  return axios.post(`${Brand_URL}/add`,  formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
    }}).then((response) => {
    return response;
  });
};

const updateBrand = (formData: FormData, id: any) => {
  return axios.put(`${Brand_URL}/edit/${id}`,  formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
    }}).then((response) => {
    return response;
  });
};

const viewBrand = (id: any) => {
  return axios.get(`${Brand_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteBrand = (id: any , userId : number ) => {
  return axios.put(`${Brand_URL}/delete/${id}` ,{userId}).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Brand_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listBrand,
  createBrand,
  updateBrand,
  viewBrand,
  deleteBrand,
  checkDuplicate,
};
