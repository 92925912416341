import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/dashboard`;

const dashboardCount = () => {
  return axios.get(`${API_URL}/count`).then((response) => {
    return response.data;
  });
};



export { dashboardCount };
