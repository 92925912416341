import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const CHANGE_PASSWORD_URL = `${API_URL}/login/change-password`


const ChangePasswordAPI =(userId:any,data:any)=> {
    return axios.put(`${CHANGE_PASSWORD_URL}/${userId}`,data)
    .then((response) => response)
};

export {ChangePasswordAPI}
