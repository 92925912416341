import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Category_URL = `${API_URL}/category`;

const listCategory = () => {
  return axios.get(`${Category_URL}/list`).then((response) => {
    return response;
  });
};

const createCategory = (data: any) => {
  return axios.post(`${Category_URL}/add`, data).then((response) => {
    return response;
  });
};

const updateCategory = (data: any, id: any) => {
  return axios.put(`${Category_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewCategory = (id: any) => {
  return axios.get(`${Category_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteCategory = (id: any , userId : number ) => {
  return axios.put(`${Category_URL}/delete/${id}`,{userId}).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Category_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listCategory,
  createCategory,
  updateCategory,
  viewCategory,
  deleteCategory,
  checkDuplicate,
};
