import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavLink,
  NavItem,
  Row,
} from "reactstrap";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import moment from "moment";
import { useProfile } from "../../Components/Hooks/UserHooks";
import { viewUser } from "./_requests";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { listOrder } from "../../pages/Orders/_requests";

export function ViewUser() {
  const { id } = useParams();
  const { userProfile } = useProfile();
  const history = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [order, setOrder] = useState<any>([]);
  const [user, setUser] = useState<any>([]);
  const [filteredOrders, setFilteredOrders] = useState<any>([]);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      
      if (type !== "all") {
        const newFilteredOrders = order.filter((order: any) => order.delivery_status == type);
        setFilteredOrders(newFilteredOrders);
      } else {
        setFilteredOrders(order);
      }
    }
  };

  useEffect(() => {
    viewUser(id).then((res: any) => {
      setUser(res);
    });

    listOrder(id).then((res: any) => {
      setOrder(res);
      setFilteredOrders(res);
    });
  }, []);

  const [PlanAccess, setPlanAccess] = useState<any>([]);

  useEffect(() => {
    // getCRUD(userProfile?.role, 17).then((res: any) => {
    //   setPlanAccess(res.Data[0]);
    // });
  }, []);

  //
  document.title = " Tuymai | Admin panel";
  function goBack() {
    history(-1);
  }

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");
    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    // deleteCheckbox();
  }, []);

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="orderCheckBox form-check-input"
              value={cell.getValue()}
            />
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Order Id",
        accessorKey: "order_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="/orders/view" className="fw-medium link-primary">
              {cell.getValue()}
            </Link>
          );
        },
      },
      {
        header: "Product",
        accessorKey: "product",
        enableColumnFilter: false,
      },
      {
        header: "Order Date",
        accessorKey: "order_date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {handleValidDate(cell.getValue())},
            <small className="text-muted">
              {" "}
              {handleValidTime(cell.getValue())}
            </small>
          </>
        ),
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "Payment Method",
        accessorKey: "payment",
        enableColumnFilter: false,
      },
      {
        header: "Delivery Status",
        accessorKey: "delivery_status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 1:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  Pending
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase bg-secondary-subtle text-secondary">
                  Inprogress
                </span>
              );
            case 3:
              return (
                <span className="badge text-uppercase bg-info-subtle text-info">
                  Pickups
                </span>
              );
            case 4:
              return (
                <span className="badge text-uppercase bg-primary-subtle text-primary">
                  Returns
                </span>
              );

            case 5:
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  Delivered
                </span>
              );
            case 6:
              return (
                <span className="badge text-uppercase bg-danger-subtle text-danger">
                  Cancelled
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  Pending
                </span>
              );
          }
        },
      },

      {
        header: "Action",
        cell: (cellProps: any) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link to="/orders/view" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    // handleOrderClick(orderData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    // onClickDelete(orderData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );



  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ToastContainer closeButton={false} limit={1} />
            <BreadCrumb title=" View User " pageTitle="User" />
            <Row>
              <Col>
                {" "}
                <button
                  onClick={goBack}
                  className="btn btn-primary px-2 text-center mb-3"
                >
                  <i className="ri ri-reply-line fs-6 me-2"></i> Go Back{" "}
                </button>{" "}
              </Col>
            </Row>
            <Card>
              <CardHeader>User Details</CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col className="col-4">Profile</Col>
                  <Col className="col-3">
                    <img
                      className="avatar-lg"
                      src={user?.profile}
                      alt="user-profile"
                    ></img>{" "}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Name</Col>
                  <Col className="col-3">{user?.name} </Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Plan</Col>
                  <Col className="col-3">{user?.plan?.name}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Plan Start Date</Col>
                  <Col className="col-3">{user?.start_date}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Plan End Date</Col>
                  <Col className="col-3">{user?.end_date}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Phone Number</Col>
                  <Col className="col-3">{user?.phone_no}</Col>
                </Row>
                <Row className="mb-3">
                  <Col className="col-4">Email</Col>
                  <Col className="col-3">{user?.email}</Col>
                </Row>
                {/* <Row className="mb-3">
                  <Col className="col-4">Address</Col>
                  <Col className="col-3">No :12 ,VKR street, pollachi</Col>
                </Row> */}
              </CardBody>
            </Card>

            <Row>
              <Col lg={12}>
                <Card id="orderList">
                  <CardHeader className="card-header border-0">
                    <Row className="align-items-center gy-3">
                      <div className="col-sm">
                        <h5 className="card-title mb-0">Order History</h5>
                      </div>
                    </Row>
                  </CardHeader>

                  <CardBody className="pt-0">
                    <div>
                      <Nav
                        className="nav-tabs nav-tabs-custom nav-success"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => toggleTab("1", "all")}
                            href="#"
                          >
                            <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                            All Orders
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => toggleTab("2", "5")}
                            href="#"
                          >
                            <i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
                            Delivered
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => toggleTab("4", "4")}
                            href="#"
                          >
                            <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>{" "}
                            Returns
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => toggleTab("5", "6")}
                            href="#"
                          >
                            <i className="ri-close-circle-line me-1 align-bottom"></i>{" "}
                            Cancelled
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TableContainer
                        columns={columns}
                        data={filteredOrders || []}
                        isGlobalFilter={true}
                        customPageSize={8}
                        divClass="table-responsive table-card mb-1 mt-0"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted text-uppercase"
                        // isOrderFilter={true}
                        // SearchPlaceholder='Search for order ID, customer, order status or something...'
                        SearchPlaceholder="Search Order..."
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
