
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const ROLE_URL = `${API_URL}/page-access`


 
const getRole= async () => {
  const res = await axios.get(`${ROLE_URL}/getRole`)
  return res;
} 

  const createroleaccess= async (data:any) => {
    const res = await axios.post(`${ROLE_URL}/addNewRoleAccess`,data)
    return res;   
  } 
  const getroleaccess= async (roleId?:any) => {
    const res = await axios.get(`${ROLE_URL}/getExistingPermissions/${roleId}`)
    return res;
  } 
  const getrolewisesidemenu= async (roleId:any) => {
    const res = await axios.get(`${ROLE_URL}/getModulesForSidebar/${roleId}`);
    return res;
   } 
   const routeRedirectSidemenu=async (roleId:any) => {
    const res = await axios.get(`${ROLE_URL}/getRouteLinkList/${roleId}`)
    return res;
   }
  const widgetCount= async (roletype:any,roletypeId:any,type:any,typeId:any) => {
    const res = await axios.get(`${ROLE_URL}/widgetCount/${roletype}/${roletypeId}/${type}/${typeId}`)
    return res;
    
  } 
export {
    getRole,createroleaccess,getroleaccess,getrolewisesidemenu,widgetCount,routeRedirectSidemenu
}
