import { createContext, useContext, useState } from "react";

const ToastContext = createContext<any>(null);

export const useToast = () => useContext(ToastContext);
// Define a more strict type for the toast state


export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toaster, setToaster] = useState<{ message: string; status: boolean } | null>(null);
  const showToast = (message: string, status: boolean) => {
    setToaster({ message, status : status });
  };

  const clearToast = () => {
    setToaster(null);
  };

  return (
    <ToastContext.Provider value={{ toaster, showToast, clearToast }}>
      {children}
    </ToastContext.Provider>
  );
};
