import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

import { productDetailsWidgets, reviews } from "../../common/data/ecommerce";

import classnames from "classnames";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation } from "swiper/modules";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import { deleteProduct, listVariant, viewProduct } from "./_requests";
import "./ecommerce.css";
import DeleteModal from "../../Components/Common/DeleteModal";
import { useToast } from "../../Components/Common/ToastContext";

interface FormattedData {
  variant: string;
  mrp: string;
  offer_type: number;
  offer_value: number;
  actual_amount: string;
  stock: number;
  sku: string;
}

interface AvailableVariant {
  id: number;
  name: string;
}

interface VariantDetail {
  variant: number;
  value: string;
}

interface VariantData {
  [key: string]: string | number; // Dynamic keys for variant data
}

interface Result {
  variant: VariantDetail[];
  variantData: VariantData;
}
type GroupedVariantData = {
  [key: string]: string[]; // Dynamic variant types with their values
};

const ProductReview = (props: any) => {
  return (
    <React.Fragment>
      <li className="py-2">
        <div className="border border-dashed rounded p-3">
          <div className="d-flex align-items-start mb-3">
            <div className="hstack gap-3">
              <div className="badge rounded-pill bg-success mb-0">
                <i className="mdi mdi-star"></i> {props.review.rating}
              </div>
              <div className="vr"></div>
              <div className="flex-grow-1">
                <p className="text-muted mb-0">{props.review.comment}</p>
              </div>
            </div>
          </div>
          {props.review.subItems && (
            <React.Fragment>
              <div className="d-flex flex-grow-1 gap-2 mb-3">
                {props.review.subItems.map((subItem: any, key: any) => (
                  <React.Fragment key={key}>
                    <Link to="#" className="d-block">
                      <img
                        src={subItem.img}
                        alt=""
                        className="avatar-sm rounded object-fit-cover"
                      />
                    </Link>
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          )}

          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <h5 className="fs-15 mb-0">{props.review.name}</h5>
            </div>

            <div className="flex-shrink-0">
              <p className="text-muted mb-0">{props.review.date}</p>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

const PricingWidgetList = ({ pricingDetails, variantData }: any) => {
  const { mrp, offer_type, offer_value, actual_amount, stock, skuId } =
    variantData || {};

  // Dynamically determine the label details
  const labelDetail =
    pricingDetails?.label === "Price" ? (
      offer_type === 1 || offer_type === 2 ? (
        <div>
          <div>
            <del>MRP: ${mrp}</del>
          </div>{" "}
          {/* MRP on its own line with strikethrough */}
          <div>Price: ${actual_amount}</div>{" "}
          {/* Actual price on the next line */}
          <div className="text-success">
            {offer_type === 1
              ? `${offer_value}% Discount`
              : `Offer Price: $${offer_value}`}
          </div>{" "}
          {/* Discount percentage below the price */}
        </div>
      ) : (
        `MRP: $${actual_amount}`
      ) // Fallback in case no offer_type
    ) : pricingDetails?.label === "Stocks" ? (
      stock
    ) : pricingDetails?.label === "SKU" ? (
      skuId
    ) : null;

  return (
    <React.Fragment>
      <Col lg={6} xl={6}>
        <div className="m-2 p-2 border border-dashed rounded">
          <div className="d-flex align-items-center">
            <div className="avatar-sm me-2">
              <div className="avatar-title rounded bg-transparent text-success fs-24">
                <i className={pricingDetails?.icon}></i>
              </div>
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-1">{pricingDetails?.label}</p>
              <h5 className="mb-0">{labelDetail || "N/A"}</h5>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

function EcommerceProductDetail(props: any) {
  document.title = " Tuymai | Admin panel";
  const { id } = useParams();
  const { showToast } = useToast();
  const history = useNavigate();
  const [editTooltipOpen, setEditTooltipOpen] = useState(false);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);

  const [customActiveTab, setcustomActiveTab] = useState<any>("1");
  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const [selectedVariantData, setSelectedVariantData] = useState({
    mrp: null,
    offer_type: null,
    offer_value: null,
    actual_amount: null,
    stock: null,
    skuId: null,
  });
  const [product, setProduct] = useState<any>(null);
  const [variant, setVariant] = useState<any[]>([]); // Ensure `variant` is initialized as an empty string
  const [variantData, setVariantData] = useState<any[]>([]); // Store the variant combinations
  const [groupedVariantData, setGroupedVariantData] =
    useState<GroupedVariantData>({}); // Store the grouped variant combinations

  const [selectedVariants, setSelectedVariants] = useState<{
    [key: string]: string;
  }>({});

  const [availableVariants, setAvailableVariants] = useState<any[]>([]);
  // Handle both variant selection and updating the actual_amount/stock/sku data
  const handleVariantSelection = (variantType: string, variant: string) => {
    setSelectedVariants((prevState) => {
      // Create a new selectedVariants object based on the selected variant type
      const newSelectedVariants = { ...prevState, [variantType]: variant };
  
      // Find a matching variant combination using the selected variants
      const selectedCombination = variantData.find((data) =>
        Object.keys(newSelectedVariants).every(
          (key) => data[key] === newSelectedVariants[key]
        )
      );
  
      // If a valid combination is found, update the selectedVariantData
      if (selectedCombination) {
        setSelectedVariantData({
          mrp: selectedCombination.mrp,
          offer_value: selectedCombination.offer_value,
          offer_type: selectedCombination.offer_type,
          actual_amount: selectedCombination.actual_amount,
          stock: selectedCombination.stock,
          skuId: selectedCombination.skuId,
        });
      }
    return newSelectedVariants;
    });
  };
  

  useEffect(() => {
    viewProduct(id).then((res: any) => {
      setProduct(res.data);
    });

    listVariant().then((res: any) => {
      setAvailableVariants(res.data);
    });
  }, []);

  useEffect(() => {
    const result = retrieveVariants(product?.variants ?? [], availableVariants);

    // Create a Set to track unique variants
    const uniqueVariants = new Set<string>();
    const filteredVariants = result.flatMap((r) => {
      return r.variant.filter((v) => {
        const variantKey = `${v.variant}-${v.value}`;
        if (!uniqueVariants.has(variantKey)) {
          uniqueVariants.add(variantKey);
          return true; // Keep this variant
        }
        return false; // Skip this variant
      });
    });

    // Set the state with the retrieved data
    setVariantData(result.map((r) => r.variantData)); // Set variantData state
    setVariant(filteredVariants); // Set variants state without duplicates
  }, [availableVariants, product]);

  useEffect(() => {
    setGroupedVariantData(groupedVariants);
  }, [variant, availableVariants]);
  useEffect(() => {
    if (variantData.length > 0) {
      // Automatically select the first valid combination
      const initialCombination = variantData[0];
      const initialSelectedVariants = Object.keys(initialCombination)
        .filter(
          (key) => !['actual_amount', 'stock', 'skuId', 'mrp', 'offer_type', 'offer_value'].includes(key)
        )
        .reduce((acc: any, key) => {
          acc[key] = initialCombination[key];
          return acc;
        }, {});
  
      setSelectedVariants(initialSelectedVariants);
      setSelectedVariantData({
        mrp: initialCombination.mrp,
        offer_type: initialCombination.offer_type,
        offer_value: initialCombination.offer_value,
        actual_amount: initialCombination.actual_amount,
        stock: initialCombination.stock,
        skuId: initialCombination.skuId,
      });
    }
  }, [variantData]);
  
const retrieveVariants = (
  formattedData: FormattedData[],
  availableVariants: AvailableVariant[]
): Result[] => {
  return formattedData?.map((item) => {
    // Log the item to see what data is being processed
    console.log("Processing item:", item);

    // Check if item is valid and if item.variant is not null or undefined
    if (!item || !item.variant) {
      console.warn("Invalid item or missing variant:", item);
      return {
        variant: [],
        variantData: {},
      };
    }

    // Split the variant string into components, ensuring it's a string
    const variantParts = String(item.variant).split(',');

    // Initialize an object to hold the retrieved data
    const retrieved: Result = {
      variant: [],
      variantData: {},
    };

    // Use a Set to keep track of processed variant IDs and combinations
    const processedVariantIds = new Set<string>();

    // Loop through the variant parts to retrieve original values
    variantParts.forEach((part) => {
      const [variantId, value] = part.split('-');
      const id = parseInt(variantId);

      // Create a unique key for the variant combination
      const variantKey = `${id}-${value}`;

      // Check if this variant combination has already been processed
      if (!processedVariantIds.has(variantKey)) {
        processedVariantIds.add(variantKey); // Mark this variant combination as processed

        // Find the matching available variant
        const availableVariant = availableVariants.find((v) => v.id === id);
        if (availableVariant) {
          retrieved.variant.push({ variant: id, value: value });
        } else {
          console.warn("No available variant found for ID:", id);
        }
      }
    });

    // Build the variantData based on the retrieved variant values
    retrieved.variant.forEach((v) => {
      const variantName = availableVariants.find(
        (av) => av.id === v.variant
      )?.name; // Get the variant name
      if (variantName) {
        retrieved.variantData[variantName] = v.value; // Add to variantData
      } else {
        console.warn("Variant name not found for:", v);
      }
    });

    // Add relevant pricing and stock data from formattedData
    retrieved.variantData.actual_amount = parseFloat(item.actual_amount) || 0; // Convert actual_amount to number, fallback to 0
    retrieved.variantData.mrp = item.mrp || 0;
    retrieved.variantData.offer_type = item.offer_type || '';
    retrieved.variantData.offer_value = item.offer_value || 0;
    retrieved.variantData.stock = item.stock || 0;
    retrieved.variantData.skuId = item.sku || '';

    return retrieved;
  });
};

  

  // Assuming `availableVariants` and `variant` are defined in your component
  const groupedVariants = useMemo(() => {
    // Group the variants by their type (e.g., Color, Size)
    const grouped = variant.reduce((acc, curr) => {
      const variantType =
        availableVariants.find((variant) => variant.id === curr.variant)
          ?.name || "Unknown"; // Get the name from availableVariants

      if (!acc[variantType]) {
        acc[variantType] = [];
      }
      acc[variantType].push(curr.value);
      return acc;
    }, {} as Record<string, string[]>);

    return grouped;
  }, [variant, availableVariants]);

  console.log("groupedVariantData", groupedVariantData);
  console.log("variantData", variantData);

  const formatDate = (dateString: any) => {
    // Check for invalid date value
    if (!dateString || dateString === "0000-00-00 00:00:00") {
      return "N/A";
    }

    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long", // Use '2-digit' for numeric month
      day: "numeric",
    };

    return date.toLocaleDateString("en-US", options); // Adjust locale as needed
  };
  const sanitizeHTML = useCallback(
    (html: any) => {
      // Replace unwanted tags or attributes here (this is a very basic example).
      return html?.replace(/<script[^>]*>([\S\s]*?)<\/script>/gi, "");
    },
    [product?.longDesc]
  );

  const sanitizedContent = sanitizeHTML(product?.longDesc);

  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleDeleteProduct = async () => {
    if (product) {
      const res: any = await deleteProduct(product.id);
      if (res.status === true) {
        showToast(res.message, res.status);
        history("/products");
      } else {
        showToast(res.message, res.status);
      }
      setDeleteModal(false);
    }
  };

  return (
    <div className="page-content">
      <DeleteModal
        content="Product"
        value={product?.name}
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <BreadCrumb title="Product Details" pageTitle="Ecommerce" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={4} md={8} className="mx-auto">
                    <div className="product-img-slider sticky-side-div">
                      <Swiper
                        modules={[Navigation, FreeMode]}
                        navigation={true}
                        centeredSlides={true} // Center active slide
                        slidesPerView={1} // Show one slide at a time
                        spaceBetween={0} // No space between slides
                        className="swiper product-thumbnail-slider p-0 rounded bg-light" // Adjust padding
                      >
                        {product?.galleries?.map((item: any) => (
                          <SwiperSlide
                            key={item.id}
                            className="position-relative"
                          >
                            {item.icon.endsWith(".mp4") ||
                            item.icon.endsWith(".webm") ? (
                              <video
                                className="img-fluid"
                                style={{
                                  height: "250px",
                                  width: "100%", // Set width to 100% to fit container
                                  objectFit: "cover", // Maintain aspect ratio
                                }}
                                controls
                              >
                                <source src={item.icon} type="video/mp4" />
                                <source src={item.icon} type="video/webm" />
                                Your browser does not support the video tag.
                              </video>
                            ) : (
                              <img
                                src={item.icon}
                                alt="image"
                                className="img-fluid"
                                style={{
                                  height: "250px",
                                  width: "100%", // Set width to 100% to fit container
                                  objectFit: "cover", // Maintain aspect ratio
                                }}
                              />
                            )}
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className="product-nav-slider mt-2">
                        <Swiper
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          spaceBetween={10}
                          className="swiper product-nav-slider mt-2 overflow-hidden"
                        >
                          {product?.galleries?.map((item: any) => (
                            <SwiperSlide key={item.id} className="rounded">
                              <div className="nav-slide-item">
                                {item.icon.endsWith(".mp4") ||
                                item.icon.endsWith(".webm") ? (
                                  <video
                                    className="img-fluid w-100"
                                    style={{
                                      height: "50px",
                                      objectFit: "cover",
                                    }} // Maintain aspect ratio
                                    controls
                                  >
                                    <source src={item.icon} type="video/mp4" />
                                    <source src={item.icon} type="video/webm" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img
                                    src={item.icon}
                                    alt="thumbnail"
                                    className="img-fluid d-block rounded"
                                    style={{
                                      height: "50px",
                                      width: "100%", // Set width to 100% to fit container
                                      objectFit: "cover", // Maintain aspect ratio
                                    }}
                                  />
                                )}
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className="mt-xl-0 mt-5">
                      <div className="d-flex flex-column flex-md-row align-items-md-start justify-content-between">
                        <div className="flex-grow-1">
                          <h4>{product?.name}</h4>
                          <div className="d-flex flex-column flex-sm-row flex-wrap gap-2 gap-sm-3">
                            <div className="text-muted">
                              Brand:{" "}
                              <span className="text-body fw-medium">
                                {product?.brands?.name}
                              </span>
                            </div>
                            <div className="vr d-none d-sm-block"></div>
                            <div className="text-muted">
                              Category:{" "}
                              <span className="text-body fw-medium">
                                {product?.categories?.name}
                              </span>
                            </div>
                            <div className="vr d-none d-sm-block"></div>
                            <div className="text-muted">
                              Published:{" "}
                              <span className="text-body fw-medium">
                                {formatDate(product?.publishedDate)}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex mt-3 mt-md-0 gap-2">
                          <div>
                            <Tooltip
                              placement="top"
                              isOpen={editTooltipOpen}
                              target="EditTooltip"
                              toggle={() =>
                                setEditTooltipOpen(!editTooltipOpen)
                              }
                            >
                              Edit
                            </Tooltip>
                            <a
                              href={`/products/edit/${id}`}
                              id="EditTooltip"
                              className="btn btn-light"
                            >
                              <i className="ri-pencil-fill align-bottom"></i>
                            </a>
                          </div>

                          <div>
                            <Tooltip
                              placement="top"
                              isOpen={deleteTooltipOpen}
                              target="DeleteTooltip"
                              toggle={() =>
                                setDeleteTooltipOpen(!deleteTooltipOpen)
                              }
                            >
                              Delete
                            </Tooltip>
                            <span
                              onClick={() => setDeleteModal(true)}
                              id="DeleteTooltip"
                              className="btn btn-light"
                            >
                                <i className="mdi mdi-trash-can-outline align-bottom"></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                        <div className="text-muted fs-16">
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                          <span className="mdi mdi-star text-warning"></span>
                        </div>
                        <div className="text-muted">
                          ( 5.50k Customer Review )
                        </div>
                      </div> */}

                      <Row className="mt-4">
                        {productDetailsWidgets.map((pricingDetails, key) => (
                          <PricingWidgetList
                            pricingDetails={pricingDetails}
                            variantData={selectedVariantData} // Pass relevant data
                            key={key}
                          />
                        ))}
                      </Row>

                      <Row>
                        {Object.entries(groupedVariantData).map(
                          ([variantType, variants]: [string, string[]]) => (
                            <Col xl={6} key={variantType}>
                              <div className="mt-4">
                                <h5 className="fs-15">
                                  {variantType.charAt(0).toUpperCase() +
                                    variantType.slice(1)}
                                  :
                                </h5>
                                <div className="d-flex flex-wrap gap-2">
                                  {variants.map((variant) => {
                                    const isActive =
                                      selectedVariants[variantType] === variant;

                                    return (
                                      <div key={variant}>
                                        <button
                                          type="button"
                                          className={`btn d-flex align-self-center border ${
                                            isActive
                                              ? "btn-success"
                                              : "border-dashed"
                                          }`}
                                          onClick={() =>
                                            handleVariantSelection(
                                              variantType,
                                              variant
                                            )
                                          }
                                        >
                                          {variant}
                                        </button>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </Col>
                          )
                        )}
                      </Row>

                      <div className="mt-4 text-muted">
                        <h5 className="fs-15">Description :</h5>
                        <p> {product?.short_description} </p>
                      </div>

                      <Row>
                        <Col sm={6}>
                          <div className="mt-3">
                            <h5 className="fs-15">Features :</h5>
                            <ul className="list-unstyled">
                              {product?.features.map(
                                (feature: any, index: any) => (
                                  <li key={index} className="py-1">
                                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                    {feature.featuers.name}: {feature.value}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mt-3">
                            <h5 className="fs-15">Specifications :</h5>
                            <ul className="list-unstyled">
                              {product?.specifications.map(
                                (spec: any, index: any) => (
                                  <li key={index} className="py-1">
                                    <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                    {spec.specifications.name}: {spec.value}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </Col>
                      </Row>

                      <div className="product-content mt-5">
                        <h5 className="fs-15 mb-3">Product Description :</h5>
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              Specification
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2");
                              }}
                            >
                              Details
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane id="nav-speci" tabId="1">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row" style={{ width: "200px" }}>
                                      HSN Code
                                    </th>
                                    <td> {product?.hsn_code}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">GST</th>
                                    <td>{product?.igst}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Weight</th>
                                    <td>{product?.weight}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Length</th>
                                    <td>{product?.length}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Breath</th>
                                    <td>{product?.breath}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Width</th>
                                    <td>{product?.width}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TabPane>
                          <TabPane id="nav-detail" tabId="2">
                            <div
                              className="mt-4"
                              dangerouslySetInnerHTML={{
                                __html: sanitizedContent,
                              }}
                            />
                          </TabPane>
                        </TabContent>
                      </div>

                      {/* <div className="mt-5">
                        <div>
                          <h5 className="fs-15 mb-3">Ratings & Reviews</h5>
                        </div>
                        <Row className="gy-4 gx-0">
                          <Col lg={4}>
                            <div>
                              <div className="pb-3">
                                <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                      <div className="fs-16 align-middle text-warning">
                                        <i className="ri-star-fill"></i>{" "}
                                        <i className="ri-star-fill"></i>{" "}
                                        <i className="ri-star-fill"></i>{" "}
                                        <i className="ri-star-fill"></i>{" "}
                                        <i className="ri-star-half-fill"></i>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h6 className="mb-0">4.5 out of 5</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <div className="text-muted">
                                    Total{" "}
                                    <span className="fw-medium">5.50k</span>{" "}
                                    reviews
                                  </div>
                                </div>
                              </div>

                              <div className="mt-3">
                                <Row className="align-items-center g-2">
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0">5 star</h6>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-success"
                                          role="progressbar"
                                          style={{ width: "50.16%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">2758</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0">4 star</h6>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-success"
                                          role="progressbar"
                                          style={{ width: "19.32%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">1063</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0">3 star</h6>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-success"
                                          role="progressbar"
                                          style={{ width: "18.12%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">997</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0">2 star</h6>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-warning"
                                          role="progressbar"
                                          style={{ width: "7.42%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">408</h6>
                                    </div>
                                  </div>
                                </Row>

                                <Row className="align-items-center g-2">
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0">1 star</h6>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className="progress-bar bg-danger"
                                          role="progressbar"
                                          style={{ width: "4.98%" }}
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">274</h6>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </Col>

                          <Col lg={8}>
                            <div className="ps-lg-4">
                              <div className="d-flex flex-wrap align-items-start gap-3">
                                <h5 className="fs-15">Reviews: </h5>
                              </div>

                              <SimpleBar
                                className="me-lg-n3 pe-lg-4"
                                style={{ maxHeight: "225px" }}
                              >
                                <ul className="list-unstyled mb-0">
                                  {reviews.map((review, key) => (
                                    <React.Fragment key={key}>
                                      <ProductReview review={review} />
                                    </React.Fragment>
                                  ))}
                                </ul>
                              </SimpleBar>
                            </div>
                          </Col>
                        </Row>
                      </div> */}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EcommerceProductDetail;
