import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/user`;

const listUser = (data?:any) => {
  return axios.post(`${API_URL}/list`,data).then((response) => {
    return response.data;
  });
};

const viewUser = (id: any) => {
  return axios.get(`${API_URL}/view/${id}`).then((response) => {
    return response.data;
  });
};

const deleteUser = (id: any) => {
  return axios.put(`${API_URL}/delete/${id}`).then((response) => {
    return response.data;
  });
};

export { listUser, viewUser, deleteUser };
