import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const GET_EMPLOYEE_LIST = `${API_URL}/employees/list`;
const ROLE_URL = `${API_URL}/roleAccess`;
const EMPLOYEE_URL = `${API_URL}/employees/add`;
const EMPLOYEE_VIEW = `${API_URL}/employees/view`;
const EMPLOYEE_UPDATE = `${API_URL}/employees/edit`;
const EMPLOYEE_DELETE = `${API_URL}/employees/delete`;
const EMPLOYEE_Duplicate = `${API_URL}/employees/find-duplicate`;
const listEmployee = () => {
  return axios.get(`${GET_EMPLOYEE_LIST}`).then((d) => {
    return d;
  });
};

const getRole = async () => {
  const res = await axios.get(`${ROLE_URL}/getRole`);

  return res;
};

const createEmployee = (formData: FormData) => {
  return axios
    .post(EMPLOYEE_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
      }
    })
    .then((response) => {
      return response.data;
    });
};

const deleteEmployee = (id: any, userId : number ) => {
  return axios.put(`${EMPLOYEE_DELETE}/${id}`,{userId}).then((response) => {
    return response.data;
  });
};

const viewEmployee = (id: any) => {
  return axios.get(`${EMPLOYEE_VIEW}/${id}`).then((response) => {
    return response.data;
  });
};

const updateEmployee = (formData: FormData, id: any) => {
  return axios
    .put(`${EMPLOYEE_UPDATE}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set Content-Type header to multipart/form-data
      }
    })
    .then((response) => {
      return response;
    });
};

const checkDuplicateEmail = (email: any) => {
  return axios.post(`${EMPLOYEE_Duplicate}`, { email }).then((response) => {
    return response;
  });
};

export {
  listEmployee,
  deleteEmployee,
  viewEmployee,
  createEmployee,
  getRole,
  updateEmployee,
  checkDuplicateEmail,
};
