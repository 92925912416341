import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Features_URL = `${API_URL}/featuer`;

const listFeatures = () => {
  return axios.get(`${Features_URL}/list`).then((response) => {
    return response;
  });
};

const createFeatures = (data: any) => {
  return axios.post(`${Features_URL}/add`, data).then((response) => {
    return response;
  });
};

const updateFeatures = (data: any, id: any) => {
  return axios.put(`${Features_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewFeatures = (id: any) => {
  return axios.get(`${Features_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteFeatures = (id: any) => {
  return axios.put(`${Features_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Features_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listFeatures,
  createFeatures,
  updateFeatures,
  viewFeatures,
  deleteFeatures,
  checkDuplicate,
};
