import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const PlanType_URL = `${API_URL}/plan-type`;

const listPlanType = () => {
  return axios.get(`${PlanType_URL}/list`).then((response) => {
    return response;
  });
};

const createPlanType = (data: any) => {
  return axios.post(`${PlanType_URL}/add`, data).then((response) => {
    return response;
  });
};

const updatePlanType = (data: any, id: any) => {
  return axios.put(`${PlanType_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewPlanType = (id: any) => {
  return axios.get(`${PlanType_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deletePlanType = (id: any) => {
  return axios.put(`${PlanType_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${PlanType_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listPlanType,
  createPlanType,
  updatePlanType,
  viewPlanType,
  deletePlanType,
  checkDuplicate,
};
