import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

//import images
import { Link } from "react-router-dom";
import { viewEmployee } from "../../pages/Employee/_requests";

const ProfileDropdown = () => {

  const [Employee, setEmployee] = useState<any>([]);
  useEffect(() => {
    const storedUser = sessionStorage.getItem("authUser");

    if (storedUser) {
      // Parse storedUser to get the object
      const parsedUser = JSON.parse(storedUser);
      // Check if parsedUser has a userId property and call viewEmployee
      if (parsedUser) {
          setEmployee(parsedUser);
      } else {
        console.error("UserId not found in storedUser");
      }
    }
  }, []);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={Employee?.profile}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {Employee?.roleName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {Employee?.email}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {Employee?.name}!</h6>

          <DropdownItem className="p-0">
            <Link to={`/profile`} className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link to="/change-password" className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Change Password</span>
            </Link>
          </DropdownItem>
          <DropdownItem className="p-0">
            <Link
              to="/login"
              className="dropdown-item"
              onClick={() => sessionStorage.clear()}
            >
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                Logout
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
