import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";
import { dashboardCount } from "./_requests";

const Widgets = () => {
    const [orderList, setOrderList] = useState<any>({});
    useEffect(() => {
    
        dashboardCount().then((res: any) => {
          setOrderList(res);
        });
    
      }, []);

console.log("orderList", orderList);
  const widgetData = [
    {
      id: 1,
      label: "Total Customers",
      counter: orderList?.TotalCustomers || 0,
      bgcolor: "primary",
      icon: "bx bx-user",
    },
    {
      id: 2,
      label: "Total Orders",
      counter: orderList?.TotalOrders || 0,
      bgcolor: "info",
      icon: "bx bx-cart",
    },
    {
      id: 3,
      label: "Completed Orders",
      counter: orderList?.CompletedOrders || 0,
      bgcolor: "success",
      icon: "bx bx-check-circle",
    },
    {
      id: 4,
      label: "Pending Orders",
      counter: orderList?.PendingOrders || 0,
      bgcolor: "warning",
      icon: "bx bx-time-five",
    },
  ];

  return (
    <React.Fragment>
      {widgetData.map((item: any, key: any) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="fw-medium text-muted text-truncate mb-0">
                    {item.label}
                  </p>
                </div>
                {/* <div className="flex-shrink-0">
                                    <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                                        {item.badge ? <i className={"fs-13 align-middle " + item.badge}></i> : null} {item.percentage} %
                                    </h5>
                                </div> */}
              </div>
              <div className="d-flex  justify-content-between flex-wrap mt-4">
                <h4 className="fs-22 fw-semibold ff-secondary mb-0 me-3">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      separator={item.separator}
                      end={item.counter}
                      duration={4}
                    />
                  </span>
                </h4>
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className={`avatar-title rounded fs-3 bg-${item.bgcolor}`}
                  >
                    <i className={`${item.icon}`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
