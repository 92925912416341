import { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Collapse,
  Button,
  ModalFooter,
  ModalBody,
  Modal,
  ModalHeader,
  Label,
} from "reactstrap";
import "./order.css";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import EcommerceOrderProduct from "./OrderProduct";
import { OrderStatus, UpdateOrderStatus, viewOrder } from "./_requests";
import OrderTracking from "./OrderTracking";
import { useToast } from "../../Components/Common/ToastContext";
import { toast, ToastContainer } from "react-toastify";
const OrderDetail = (props: any) => {
  const { id } = useParams();
  const { showToast, toaster, clearToast } = useToast();
  const [order, setOrder] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (toaster?.message) {
      if (toaster.status === true) {
        toast.success(toaster.message, { autoClose: 3000 });
      } else {
        toast.error(toaster.message, { autoClose: 3000 });
      }

      clearToast(); // Clear the toast data after showing
    }
  }, [toaster, clearToast]);

  useEffect(() => {
    viewOrders();

    OrderStatus().then((res: any) => {
      setStatus(res.data);
    });
  }, []);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const viewOrders = useCallback(() => {
    viewOrder(id).then((res: any) => {
      setOrder(res.data);
    });
  }, [id]);

  const handleStatus = async () => {
    const data = { order_id: id, tracking_status: selectedStatus };
    console.log("data", data);
    const res: any = await UpdateOrderStatus(data);
    if (res.status === true) {
      showToast(res.message, res.status);
      viewOrders();
    } else {
      showToast(res.message, res.status);
    }
    toggleModal();
  };
  document.title = "Order Details | Velzon - React Admin & Dashboard Template";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Order Details" pageTitle="Ecommerce" />
        <ToastContainer closeButton={false} limit={1} />
        <Row>
          <Col xl={9}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">
                    {order?.order_id}
                  </h5>
                  {/* <div className="flex-shrink-0">
                    <Link
                      to="/apps-invoices-details"
                      className="btn btn-success btn-sm"
                    >
                      <i className="ri-download-2-fill align-middle me-1"></i>{" "}
                      Invoice
                    </Link>
                  </div> */}
                </div>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-card">
                  <table className="table table-nowrap align-middle table-borderless mb-0">
                    <thead className="table-light text-muted">
                      <tr>
                        <th scope="col" className="w-40">
                          Product Details
                        </th>
                        <th scope="col" className="w-20">
                          Item Price
                        </th>
                        <th scope="col" className="w-20">
                          Quantity
                        </th>
                        <th scope="col" className="text-end w-20">
                          Total Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.Order_item?.map((product: any) => (
                        <EcommerceOrderProduct
                          product={product}
                          key={product.id}
                        />
                      ))}
                      <tr className="border-top border-top-dashed">
                        <td colSpan={3}></td>
                        <td colSpan={2} className="fw-medium p-0">
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td className="w-50">Sub Total :</td>
                                <td className="text-end w-50">
                                  {order.sub_total_amount}
                                </td>
                              </tr>
                              <tr>
                                <td className="w-50">Discount :</td>
                                <td className="text-end w-50">
                                  -{order.discount}
                                </td>
                              </tr>
                              <tr>
                                <td className="w-50">Shipping Charge :</td>
                                <td className="text-end w-50">
                                  {order.shipping_charge}
                                </td>
                              </tr>
                              <tr>
                                <td className="w-50">Estimated Tax :</td>
                                <td className="text-end w-50">{order.tax}</td>
                              </tr>
                              <tr className="border-top border-top-dashed">
                                <th scope="row" className="w-50">
                                  Total :
                                </th>
                                <th className="text-end w-50">
                                  {order.total_amount}
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <div className="d-sm-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">Order Status</h5>
                  <div className="flex-shrink-0 mt-2 mt-sm-0">
                    <div
                      className="btn btn-soft-info btn-sm mt-2 mt-sm-0"
                      onClick={toggleModal}
                    >
                      <i className="ri-map-pin-line align-middle me-1"></i>{" "}
                      Order Status update
                    </div>{" "}
                    {/* <Link
                      to="#"
                      className="btn btn-soft-info btn-sm mt-2 mt-sm-0"
                    >
                      <i className="ri-map-pin-line align-middle me-1"></i>{" "}
                      Change Address
                    </Link>{" "}
                    <Link
                      to="#"
                      className="btn btn-soft-danger btn-sm mt-2 mt-sm-0"
                    >
                      <i className="mdi mdi-archive-remove-outline align-middle me-1"></i>{" "}
                      Cancel Order
                    </Link> */}
                  </div>
                </div>
              </CardHeader>
              <OrderTracking orderTracking={order?.Order_tracking} />
            </Card>
          </Col>

          <Col xl={3}>
            {/* <Card>
              <CardHeader>
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">
                    <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                    Logistics Details
                  </h5>
                  <div className="flex-shrink-0">
                    <Link
                      to="#"
                      className="badge bg-primary-subtle text-primary fs-11"
                    >
                      Track Order
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="text-center">
                  <i className="ri-truck-line display-5 text-danger"></i>
                  <h5 className="fs-16 mt-2">RQK Logistics</h5>
                  <p className="text-muted mb-0">ID: MFDS1400457854</p>
                  <p className="text-muted mb-0">Payment Mode : Debit Card</p>
                </div>
              </CardBody>
            </Card> */}

            <Card>
              <CardHeader>
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">
                    Customer Details
                  </h5>
                  <div className="flex-shrink-0">
                    <Link
                      to={`/user/view/${order?.users?.id}`}
                      className="link-secondary"
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled mb-0 vstack gap-3">
                  <li>
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <img
                          src={order.users?.profile}
                          alt={order.users?.name}
                          className="avatar-sm rounded"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fs-14 mb-1">{order.users?.name}</h6>
                      </div>
                    </div>
                  </li>
                  {order.users?.email && (
                    <li>
                      <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                      {order.users.email}
                    </li>
                  )}
                  {order.users?.phone_no && (
                    <li>
                      <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                      {order.users.phone_no}
                    </li>
                  )}
                </ul>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">
                  <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}
                  Billing Address
                </h5>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                  {order.billingAddress?.user?.name && (
                    <li className="fw-medium fs-14">
                      {order.billingAddress.user.name}
                    </li>
                  )}
                  {order.billingAddress?.phone_no && (
                    <li>{order.billingAddress.phone_no}</li>
                  )}
                  {order.billingAddress?.address && (
                    <li>{order.billingAddress.address}</li>
                  )}
                  {order.billingAddress?.city && (
                    <li>{order.billingAddress.city}</li>
                  )}
                  {order.billingAddress?.state && (
                    <li>{order.billingAddress.state}</li>
                  )}
                  {order.billingAddress?.land_mark && (
                    <li>{order.billingAddress.land_mark}</li>
                  )}
                  {order.billingAddress?.postal_code && (
                    <li>{order.billingAddress.postal_code}</li>
                  )}
                  {order.billingAddress?.country && (
                    <li>{order.billingAddress.country}</li>
                  )}
                </ul>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">
                  <i className="ri-map-pin-line align-middle me-1 text-muted"></i>{" "}
                  Shipping Address
                </h5>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
                  {order.shippingAddress?.user?.name && (
                    <li className="fw-medium fs-14">
                      {order.shippingAddress.user.name}
                    </li>
                  )}
                  {order.shippingAddress?.phone_no && (
                    <li>{order.shippingAddress.phone_no}</li>
                  )}
                  {order.shippingAddress?.address && (
                    <li>{order.shippingAddress.address}</li>
                  )}
                  {order.shippingAddress?.city && (
                    <li>{order.shippingAddress.city}</li>
                  )}
                  {order.shippingAddress?.state && (
                    <li>{order.shippingAddress.state}</li>
                  )}
                  {order.shippingAddress?.land_mark && (
                    <li>{order.shippingAddress.land_mark}</li>
                  )}
                  {order.shippingAddress?.postal_code && (
                    <li>{order.shippingAddress.postal_code}</li>
                  )}
                  {order.shippingAddress?.country && (
                    <li>{order.shippingAddress.country}</li>
                  )}
                </ul>
              </CardBody>
            </Card>
            {/* 
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">
                  <i className="ri-secure-payment-line align-bottom me-1 text-muted "></i>{" "}
                  Payment Details
                </h5>
              </CardHeader>
              <CardBody className="overflow-auto">
                <div className="d-flex align-items-center mb-2 ">
                  <div className="flex-shrink-0">
                    <p className="text-muted mb-0">Transactions:</p>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">#VLZ124561278124</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <p className="text-muted mb-0">Payment Method:</p>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">Debit Card</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <p className="text-muted mb-0">Card Holder Name:</p>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">Joseph Parker</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-shrink-0">
                    <p className="text-muted mb-0">Card Number:</p>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">xxxx xxxx xxxx 2456</h6>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <p className="text-muted mb-0">Total Amount:</p>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <h6 className="mb-0">$415.96</h6>
                  </div>
                </div>
              </CardBody>
            </Card> */}
          </Col>
        </Row>

        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Update Order Status</ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <label className="form-label" htmlFor="price-input">
                Order Status
              </label>
              <select
                className="form-control"
                id="price-select"
                name="offerType"
                aria-placeholder="select offer type"
                onChange={(e) => setSelectedStatus(e.target.value)}
                value={selectedStatus}
              >
                {" "}
                <option value="">Select order status </option>
                {status.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Discard
            </Button>
            <Button color="primary" onClick={handleStatus}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default OrderDetail;
