import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import moment from "moment";
import TableContainer from '../../Components/Common/TableContainer';
import { listUser } from '../../pages/User/_requests';
const RecentCustomers = () => {
    const [orderList, setOrderList] = useState<any>([]);
    useEffect(() => {
        const data={limit : 10} ;
        listUser(data).then((res: any) => {
          setOrderList(res);
        });
    
      }, []);

      // Column
      const columns = useMemo(
        () => [
          {
            header: "S.No",
            id: "sno",
            enableColumnFilter: false,
            cell: (cellProps: any) => {
              return cellProps.row.index + 1;
            },
          },
          {
            header: "Name",
            accessorKey: "name",
            enableColumnFilter: false,
            cell: (cell: any) => (
              <>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 me-3">
                    <div className="avatar-sm bg-light rounded p-1">
                      <img
                        src={cell.row.original.profile}
                        alt=""
                        className="img-fluid d-block"
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h5 className="fs-14 mb-1">
                      <Link
                        to={`/user/view/${cell.row.original.id}`}
                        className="text-body"
                      >
                        {" "}
                        {cell.getValue()}
                      </Link>
                    </h5>
                  </div>
                </div>
              </>
            ),
          },
          {
            header: "Plan",
            accessorKey: "planId",
            enableColumnFilter: false,
          },
          {
            header: "Phone No",
            accessorKey: "phone_no",
            enableColumnFilter: false,
          },
          {
            header: "Email",
            accessorKey: "email",
            enableColumnFilter: false,
          },

        ],
        [ ]
      );




    return (
        <React.Fragment>
            <Card>
            <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Recent Customer</h5>
                  </div>
                  </Row>
                  </CardHeader>
                <CardBody>
                <div>
                  {orderList?.length ? (
                    <TableContainer
                      columns={columns}
                      data={orderList || []}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-1 mt-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                    />
                  ) : (
                    <p className="fw-bold text-center">No Results Found</p> 
                  )}
                </div>
                </CardBody>
            </Card>
    
        </React.Fragment>
    );
};

export default RecentCustomers;