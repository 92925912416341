import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Variant_URL = `${API_URL}/variant`;

const listVariant = () => {
  return axios.get(`${Variant_URL}/list`).then((response) => {
    return response;
  });
};

const createVariant = (data: any) => {
  return axios.post(`${Variant_URL}/add`, data).then((response) => {
    return response;
  });
};

const updateVariant = (data: any, id: any) => {
  return axios.put(`${Variant_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewVariant = (id: any) => {
  return axios.get(`${Variant_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteVariant = (id: any) => {
  return axios.put(`${Variant_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Variant_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listVariant,
  createVariant,
  updateVariant,
  viewVariant,
  deleteVariant,
  checkDuplicate,
};
