import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { listOrder, listOrderStatus, listPaymentMethod } from "./_requests";
import {useDownloadExcel} from 'react-export-table-to-excel'

const OrderReports = () => {
  const tableRef = useRef<HTMLTableElement>(null)
  const [orderList, setOrderList] = useState<any>([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const initialFilterData = {
    orderstatus: 0,  
    paymentmethod: 0,
  };
  const [filterData, setFilterData] = useState(initialFilterData);
  const [dateRange, setDateRange] = useState<Date[] | undefined>(undefined);


    // Handle filter changes for Payment Method and Order Status
    const handleFilterChange = (e:any) => {
      const { name, value } = e.target;
      setFilterData((prevData) => ({
        ...prevData,
        [name]: Number(value)
      }));
    };
  
    const handleDateChange = (selectedDates: Date[]) => {
      const formattedDates = selectedDates.map((date) => {
        return date.toISOString().split("T")[0]; // Format to YYYY-MM-DD
      });
      setDateRange(selectedDates); // Update dateRange with selected Date objects
      console.log("Formatted Dates for DB:", formattedDates);
    };
  
    // Reset filters to initial state
    const handleReset = () => {
      setFilterData(initialFilterData);
      setDateRange(undefined);
    };

console.log("dateRange", dateRange);


  useEffect(() => {
    listOrder().then((res: any) => {
      setOrderList(res.data);
    });

    listOrderStatus().then((res: any) => {
      setOrderStatus(res.data);
    });

    listPaymentMethod().then((res: any) => {
      setPaymentMethod(res.data);
    });
  }, []);


  // Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        accessorKey: "s_no",
        cell: (cell: any) => {
          const index = cell.row.index + 1; // Assuming you want the S.No to start from 1
          return <span>{index}</span>;
        },
      },
      {
        header: "Order Id",
        accessorKey: "order_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = cell.row.original.order_date;
          return (
            <>
              <Link
                to={`/orders/view/${cell.row.original.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
              <div>
                {handleValidDate(date)},
                <small className="text-muted"> {handleValidTime(date)}</small>
              </div>
            </>
          );
        },
      },
      {
        header: "User",
        accessorKey: "users.name",
        enableColumnFilter: false,
      },

      {
        header: "Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
      },
      {
        header: "Payment Method",
        accessorKey: "paymentMethod.name",
        enableColumnFilter: false,
      },
      {
        header: "Delivery Status",
        accessorKey: "orderStatus.id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const data = cell.row.original.orderStatus?.name;
          switch (data) {
            case 1:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 3:
              return (
                <span className="badge text-uppercase bg-danger-subtle text-danger">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase bg-secondary-subtle text-secondary">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 6:
              return (
                <span className="badge text-uppercase bg-info-subtle text-info">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 5:
              return (
                <span className="badge text-uppercase bg-primary-subtle text-primary">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 7:
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  {" "}
                  {data}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {data}{" "}
                </span>
              );
          }
        },
      },
    ],
    [ ]
  );

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };


  
  
  var DatefileName = new Date()
  var fileName =
    DatefileName.getFullYear() + '-' + (DatefileName.getMonth() + 1) + '-' + DatefileName.getDate()


  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Order_Report_${fileName}`,
    sheet: 'report',
  })


  const handleFilter=()=>{
    

    const data={
      orderStatus : filterData.orderstatus,
      paymentMethod : filterData.paymentmethod,
      dateRange
    }


    listOrder(data).then((res: any) => {
      setOrderList(res.data);
    });
  }



  document.title = " Tuymai | Admin panel";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Orders" pageTitle="Ecommerce" />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Order Report</h5>
                  </div>
                  <div className="col-sm-auto">
                    <Button
                      color="primary"
                      onClick={onDownload}
                    >
                      Download
                    </Button>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <Row className="align-items-center mb-3">
                  <Col sm="4" className="mb-2">
                    <label htmlFor="paymentMethod">Payment Method</label>
                    <select
                      id="paymentMethod"
                      name="paymentmethod"
                      className="form-select"
                      value={filterData.paymentmethod}
                      onChange={handleFilterChange}
          
                    >
                      <option value={0}>All</option>
                      {paymentMethod.map((item: any) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })} 

                    </select>
                  </Col>

                  <Col sm="4" className="mb-2">
                    <label htmlFor="orderDateRange">Order Date Range</label>
                    <div className="input-group">
                      <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        placeholder="Select date range" 
                        options={{ 
                          mode: "range",
                          dateFormat: "d M, Y",
                        }}
                        value={dateRange} // Convert to Date objects for Flatpickr
                        onChange={handleDateChange}
                      />
                      <div className="input-group-text">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </Col>

                  <Col sm="4" className="mb-2">
                    <label htmlFor="deliveryStatus">Order Status</label>
                    <select
                      id="deliveryStatus"
                              name="orderstatus"
                      className="form-select"
                      value={filterData.orderstatus}
                      onChange={handleFilterChange}
                    >
                      <option value={0}>All</option>
                      {orderStatus.map((item: any) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </Col>
                </Row>
                <Row className="align-items-center mb-2">
                  <Col sm="4">
                    <button className="btn btn-primary w-100 mb-2" onClick={handleFilter}>Filter</button>
                  </Col>
                  <Col sm="4">
                    <button className="btn btn-primary w-100 mb-2" onClick={handleReset}>Reset</button>
                  </Col>
                </Row>

                <div>
                  {orderList?.length ? (

                    <TableContainer
                      columns={columns}
                      ref={tableRef}
                      data={orderList || []}
                      customPageSize={8}
                      divClass="table-responsive table-card mb-1 mt-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                    />
                  ) : (
                    <p className="fw-bold text-center">No Results Found</p> 
                  )}
                </div>

                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderReports;
