import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const PlanFeatures_URL = `${API_URL}/plan-features`;

const listPlanFeatures = () => {
  return axios.get(`${PlanFeatures_URL}/list`).then((response) => {
    return response;
  });
};

const createPlanFeatures = (data: any) => {
  return axios.post(`${PlanFeatures_URL}/add`, data).then((response) => {
    return response;
  });
};

const updatePlanFeatures = (data: any, id: any) => {
  return axios.put(`${PlanFeatures_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const viewPlanFeatures = (id: any) => {
  return axios.get(`${PlanFeatures_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deletePlanFeatures = (id: any) => {
  return axios.put(`${PlanFeatures_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${PlanFeatures_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listPlanFeatures,
  createPlanFeatures,
  updatePlanFeatures,
  viewPlanFeatures,
  deletePlanFeatures,
  checkDuplicate,
};
