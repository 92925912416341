import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const Subscription_URL = `${API_URL}/subscription`;

const listSubscription = () => {
  return axios.get(`${Subscription_URL}/list`).then((response) => {
    return response;
  });
};

const createSubscription = (data: any) => {
  return axios.post(`${Subscription_URL}/add`, data).then((response) => {
    return response;
  });
};

const updateSubscription = (data: any, id: any) => {
  return axios.put(`${Subscription_URL}/edit/${id}`, data).then((response) => {
    return response;
  });
};

const getSubscription = (id: any) => {
  return axios.get(`${Subscription_URL}/view/${id}`).then((response) => {
    return response;
  });
};

const deleteSubscription = (id: any) => {
  return axios.put(`${Subscription_URL}/delete/${id}`).then((response) => {
    return response;
  });
};

const checkDuplicate = (name: any) => {
  return axios.post(`${Subscription_URL}/duplicate`, { name }).then((response) => {
    return response;
  });
};

export {
  listSubscription,
  createSubscription,
  updateSubscription,
  getSubscription,
  deleteSubscription,
  checkDuplicate,
};
