
import React from "react";
const EcommerceOrderProduct = ({ product }: { product: any }) => {
  const productDetails = product?.product || {};

  return (
    <React.Fragment>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 avatar-md bg-light rounded">
              <div className="ratio ratio-1x1">
                <img
                  src={productDetails.icon || "/default-icon.png"}
                  alt={productDetails.name || "Product Image"}
                  className="img-fluid rounded"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="fs-15 mb-0">
                <a
                  href={`/products/view/${productDetails.id}`}
                  className="link-primary"
                >
                  {productDetails.name || "Unnamed Product"}
                </a>
              </h5>
              <p className="text-muted mb-0">
                {product.color && (
                  <span>
                    Color: <span className="fw-medium">{product.color}</span>
                  </span>
                )}
                {product.size && (
                  <span className="ms-2">
                    Size: <span className="fw-medium">{product.size}</span>
                  </span>
                )}
              </p>
            </div>
          </div>
        </td>
        <td>{product.total_price}</td>
        <td>{product.quantity}</td>
        <td className="fw-medium text-end">
          {product.quantity * product.total_price}
        </td>
      </tr>
    </React.Fragment>
  );
};


// const EcommerceOrderProduct = (order: any) => {
//   console.log("order", order);
//   console.log("order.product", order.product);
//   console.log("order.product?.icon", order.product?.icon);
//   return (
//     <React.Fragment>
//       <tr>
//         <td>
//           <div className="d-flex">
//             <div className="flex-shrink-0 avatar-md bg-light rounded">
//               <div className="ratio ratio-1x1">
//                 <img
//                   src={order.product?.product?.icon}
//                   alt={order.product?.product?.name || "Product Image"}
//                   className="img-fluid rounded object-fit: cover"
//                 />
//               </div>
//             </div>

//             <div className="flex-grow-1 ms-3">
//               <h5 className="fs-15">
//                 <a
//                   href={`/products/view/${order.product?.product?.id}`}
//                   className="link-primary"
//                 >
//                   {order.product?.product?.name}
//                 </a>
//               </h5>
//               {/* <p className="text-muted mb-0">
//                 Color: <span className="fw-medium">{props.product.color}</span>
//               </p>
//               <p className="text-muted mb-0">
//                 Size: <span className="fw-medium">{props.product.size}</span>
//               </p> */}
//             </div>
//           </div>
//         </td>
//         <td>{order.product?.total_price}</td>
//         <td>{order.product?.quantity}</td>
//         {/* <td>
//           <div className="text-warning fs-15">
//             <i className="ri-star-fill"></i>
//             <i className="ri-star-fill"></i>
//             <i className="ri-star-fill"></i>
//             <i className="ri-star-fill"></i>
//             <i className="ri-star-half-fill"></i>
//           </div>
//         </td> */}
//         <td className="fw-medium text-end">
//           {order.product?.quantity * order.product?.total_price}
//         </td>
//       </tr>
//     </React.Fragment>
//   );
// };
export default EcommerceOrderProduct;
