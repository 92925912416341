import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const ROLE_URL = `${API_URL}/roles`


const ListRole = () => {
  return axios
    .get(`${ROLE_URL}/list`,)
    .then((response) => response.data)
    .then((data) => {
      return data; // Return the data to pass it along in the promise chain
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      throw error; // Re-throw the error to propagate it down the promise chain
    });
};



const createRole = (user:any) => {
  
    return axios
    .post(`${ROLE_URL}/add`,user,).then((response) => response.data)
}

const deleteRoleById = (id: any ,userId :any) => {
  
  return axios
    .put(`${ROLE_URL}/delete/${id}`,{userId})
    .then((response) => {
   // 
    return response.data;

    }
    )
}
const updateRole = (name:any,id: any) => {
  return axios
    .put(`${ROLE_URL}/edit/${id}`, name )
    .then((response) => {return response.data; }
    )
}


export {ListRole,createRole, deleteRoleById,updateRole}
