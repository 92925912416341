import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import moment from "moment";
import TableContainer from '../../Components/Common/TableContainer';
import { listOrder } from '../../pages/Reports/_requests';
const RecentOrders = () => {
    const [orderList, setOrderList] = useState<any>([]);
    useEffect(() => {
        const data={limit : 10} ;
        listOrder(data).then((res: any) => {
          setOrderList(res.data);
        });
    
      }, []);

      // Column
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        accessorKey: "s_no",
        cell: (cell: any) => {
          const index = cell.row.index + 1; // Assuming you want the S.No to start from 1
          return <span>{index}</span>;
        },
      },
      {
        header: "Order Id",
        accessorKey: "order_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = cell.row.original.order_date;
          return (
            <>
              <Link
                to={`/orders/view/${cell.row.original.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
              <div>
                {handleValidDate(date)},
                <small className="text-muted"> {handleValidTime(date)}</small>
              </div>
            </>
          );
        },
      },
      {
        header: "User",
        accessorKey: "users.name",
        enableColumnFilter: false,
      },

      {
        header: "Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
      },
      {
        header: "Payment Method",
        accessorKey: "paymentMethod.name",
        enableColumnFilter: false,
      },
      {
        header: "Delivery Status",
        accessorKey: "orderStatus.id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const data = cell.row.original.orderStatus?.name;
          switch (data) {
            case 1:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 3:
              return (
                <span className="badge text-uppercase bg-danger-subtle text-danger">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase bg-secondary-subtle text-secondary">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 6:
              return (
                <span className="badge text-uppercase bg-info-subtle text-info">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 5:
              return (
                <span className="badge text-uppercase bg-primary-subtle text-primary">
                  {" "}
                  {data}{" "}
                </span>
              );
            case 7:
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  {" "}
                  {data}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {data}{" "}
                </span>
              );
          }
        },
      },
    ],
    [ ]
  );

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };
    return (
        <React.Fragment>
            <Card>
            <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Recent Orders</h5>
                  </div>
                  </Row>
                  </CardHeader>
                <CardBody>
                <div>
                  {orderList?.length ? (
                    <TableContainer
                      columns={columns}
                      data={orderList || []}
                      customPageSize={10}
                      divClass="table-responsive table-card mb-1 mt-0"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                    />
                  ) : (
                    <p className="fw-bold text-center">No Results Found</p> 
                  )}
                </div>
                </CardBody>
            </Card>
    
        </React.Fragment>
    );
};

export default RecentOrders;