import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/activity-log`;

const listActivityLog = (data?: any) => {
  return axios.post(`${API_URL}/list`, data).then((response) => {
    return response;
  });
};

const viewActivityLog = (id : number) => {
  return axios.post(`${API_URL}/view/${id}`).then((response) => {
    return response;
  });
};
const listModule = () => {
  return axios.post(`${API_URL}/get-module`).then((response) => {
    return response;
  });
};

export { listActivityLog, viewActivityLog, listModule };
